import React, { useState, useEffect, useContext } from "react";
import ReactSpeedometer from "react-d3-speedometer";
import { DashboardContext } from "../../../context/DashboardContext";
import "./customGauge.scss";
import { hasProperty } from "../../../utils/helpers";
import { deepCopy } from "../../leftSide/Models/modelLogic";

const CustomGauge = ({
  value,
  name,
  minValueProp = 0,
  maxValueProp = 500,
  size = 300,
  gaugeModelID,
}) => {
  const { gaugeDict, setGaugeDict } = useContext(DashboardContext);
  const [minValue, setMinValue] = useState(minValueProp);
  const [minTemp, setMinTemp] = useState(minValueProp);
  const [maxValue, setMaxValue] = useState(maxValueProp);
  const [maxTemp, setMaxTemp] = useState(maxValueProp);

  useEffect(() => {
    if (hasProperty(gaugeDict, gaugeModelID)) {
      const dictCopy = deepCopy(gaugeDict);
      let needsUpdate = false;
      if (dictCopy[gaugeModelID].min !== minValue) {
        dictCopy[gaugeModelID].min = minValue;
        needsUpdate = true;
      }
      if (dictCopy[gaugeModelID].max !== maxValue) {
        dictCopy[gaugeModelID].max = maxValue;
        needsUpdate = true;
      }
      if (needsUpdate) {
        setGaugeDict(dictCopy);
      }
    }
  }, [gaugeDict, minValue, maxValue]);

  useEffect(() => {
    if (value > maxValue) {
      setMaxValue(value);
      setMaxTemp(value);
    } else if (value < minValue) {
      setMinValue(value);
      setMinTemp(value);
    }
  }, [value]);

  const handleMinValueBlur = () => {
    if (minTemp >= maxValue) {
      setMinValue(maxValue - 1);
      setMinTemp(maxValue - 1);
    } else if (minTemp > value) {
      setMinValue(value);
      setMinTemp(value);
    } else {
      setMinValue(minTemp);
    }
  };

  const handleMaxValueBlur = () => {
    if (maxTemp <= minValue) {
      setMaxValue(minValue + 1);
      setMaxTemp(minValue + 1);
    } else if (maxTemp < value) {
      setMaxValue(value);
      setMaxTemp(value);
    } else {
      setMaxValue(maxTemp);
    }
  };

  return (
    <div className="custom-gauge-container">
      <div className="gauge-name">{name}</div>
      <ReactSpeedometer
        value={Math.min(Math.max(value, minValue), maxValue)} // Restrict value within range, to prevent bouncy needle
        minValue={minValue}
        maxValue={maxValue}
        segments={40} // Creates many segments for a smooth gradient effect
        startColor="blue"
        endColor="red"
        needleColor="#15174a"
        height={size * 0.7}
        width={size}
        needleTransitionDuration={1}
        needleTransition="easeElastic"
        ringWidth={20}
        maxSegmentLabels={0} // Remove labels for sections to avoid overlap
        needleHeightRatio={0.6}
        forceRender={true}
        textColor="black"
      />
      <div className="gauge-controls">
        <input
          type="number"
          value={minTemp}
          onChange={(e) => setMinTemp(Number(e.target.value))}
          onBlur={handleMinValueBlur}
        />
        <input
          type="number"
          value={maxTemp}
          onChange={(e) => setMaxTemp(Number(e.target.value))}
          onBlur={handleMaxValueBlur}
        />
      </div>
    </div>
  );
};

export default CustomGauge;
