import React, { useState, useContext, useRef } from "react";
import { styled } from "@mui/material/styles";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import SettingsIcon from "@mui/icons-material/Settings";
import NotificationsIcon from "@mui/icons-material/Notifications";
// import logo from "../../res/Logos/reffitLogo.png";
import logo from "../../res/Logos/reffit-by-speqqle.png";
import { Button, Select } from "@mui/material";
// import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
// import { Link } from "react-router-dom";
import alert from "../../res/icons/notification.png";
import close from "../../res/icons/delete.png";
import { DashboardContext } from "../../context/DashboardContext";
import { AuthContext } from "../../context/AuthContext";
import { GraphContext } from "../../context/GraphContext";
import { WebSocketContext } from "../../context/WebSocketContext";
import { generateWarningList } from "./actionBarLogic";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import { useNavigate } from "react-router-dom";
import Modal from "react-modal";
import "./appBar.scss";
import {
  adjustModalPositionAndSize,
  downloadStringTxtFile,
  generateWarningObject,
  handleFileChange,
  hasProperty,
  simpleReadFile,
  vhToPixels,
} from "../../utils/helpers";
import units from "../../utils/units";
import { GeneralContext } from "../../context/GeneralContext";
import TextLoader from "../commonComponents/TextLoader";

//AppBar style
const AppBarStyle = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  toolbar: theme.mixins.toolbar,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const AppBar = () => {
  const {
    resetDashboardContext,
    warnings,
    setWarnings,
    newWarningCount,
    setNewWarningCount,
    getDashboardVariables,
    setDashboardVariables,
    setDemoOpen,
    demoOpen,
    setAddGraphModalOpen,
  } = useContext(DashboardContext);
  const { limitedToast, recordedErrorLog } = useContext(GeneralContext);
  const { resetAuth } = useContext(AuthContext);
  const { resetGraphContext, getGraphVariables, setGraphVariables } =
    useContext(GraphContext);
  const { resetWebSocketContext, isFitOngoing, isLoopOngoing } =
    useContext(WebSocketContext);
  const navigate = useNavigate();
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [modalPlaceAndSize, setModalPlaceAndSize] = useState({
    top: "0",
    left: "0",
    height: "300px",
    width: "300px",
  });
  const [settingModalOpen, setSettingModalOpen] = useState(false);
  const [xUnit, setXUnit] = useState(units[0]);
  const warningsRef = useRef(null);
  let warningsToDisplay = null;
  const fileInputRef = useRef(null);
  const settingsRef = useRef(null);

  //Logout menu toggle function anchor
  // const [anchorEl, setAnchorEl] = useState(null);
  // const open = Boolean(anchorEl);

  //Logout menu toggle open function
  const handleSettingsClick = () => {
    // setAnchorEl(event.currentTarget);
    setSettingModalOpen(true);
  };

  // //Logout menu toggle close function
  // const handleClose = () => {
  //   setAnchorEl(null);
  // };

  //Logout menu toggle close function
  const handleLogout = () => {
    resetDashboardContext();
    resetAuth();
    resetGraphContext();
    resetWebSocketContext();
    sessionStorage.clear();
    // handleClose();
    navigate("/");
  };

  const handleSessionClear = () => {
    resetDashboardContext();
    // // resetAuth();
    resetGraphContext();
    resetWebSocketContext();
    // sessionStorage.clear();
    // handleClose();
    window.location.reload();
  };

  const handleCloseModal = () => {
    setModalIsOpen(false);
  };

  const handleCloseSettingsModal = () => {
    setSettingModalOpen(false);
  };

  function handleWarningIconClick() {
    try {
      setNewWarningCount(0);
      setModalIsOpen(!modalIsOpen);

      const modalPosition = { top: vhToPixels(2), right: 280, left: "auto" };
      const modalSize = { width: 370, height: 400 };

      const adjusted = adjustModalPositionAndSize(modalPosition, modalSize);

      setModalPlaceAndSize(adjusted);
    } catch (error) {
      recordedErrorLog("Warning icon click handler has failed: ", error);
    }
  }

  function handleDelete(indexToDelete) {
    try {
      const warningTemp = [...warnings].filter(
        (warning, index) => index != indexToDelete
      );
      if (warningTemp.length < 1) {
        setModalIsOpen(false);
      }
      setWarnings(warningTemp);
    } catch (error) {
      recordedErrorLog("Delete handler failure: ", error);
    }
  }

  function handleClearAll() {
    setWarnings([]);
    setModalIsOpen(false);
  }

  function handleUserIconClick() {
    navigate("/profile");
  }

  warningsToDisplay = generateWarningList(warnings, handleDelete);

  function generateFileName() {
    try {
      const now = new Date();
      const year = now.getFullYear();
      const month = (now.getMonth() + 1).toString().padStart(2, "0");
      const day = now.getDate().toString().padStart(2, "0");
      const hours = now.getHours().toString().padStart(2, "0");
      const minutes = now.getMinutes().toString().padStart(2, "0");
      const seconds = now.getSeconds().toString().padStart(2, "0");

      // Constructing the filename string
      const fileName = `Session - ${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
      return fileName;
    } catch (error) {
      recordedErrorLog("File name generatio failed: ", error);
      return "Session";
    }
  }

  const handleSessionSaveStart = () => {
    try {
      const dashboardVariables = getDashboardVariables();
      const graphVariables = getGraphVariables();
      const combinedVariables = {
        dashboard: dashboardVariables,
        graph: graphVariables,
      };

      const variablesAsText = JSON.stringify(combinedVariables);

      const textToSave = "session\n" + variablesAsText;

      downloadStringTxtFile(textToSave, generateFileName());
    } catch (error) {
      recordedErrorLog("Saving handler failure: ", error);
    }
  };

  function convertSessionFileToVariables(fileContent) {
    try {
      // Split the content by newline to differentiate the 'session' prefix from the JSON string
      const lines = fileContent.split("\n");

      // Check if the first line is 'session'
      if (lines[0].trim() === "session") {
        // Remove the first line
        lines.shift();

        // Join the remaining lines back in case the JSON itself contained newlines
        const jsonString = lines.join("\n");

        // Parse the JSON string back into an object
        try {
          const obj = JSON.parse(jsonString);
          return obj;
        } catch (error) {
          recordedErrorLog("Error parsing JSON from file content", error);
          return null;
        }
      } else {
        recordedErrorLog("File does not start with 'session'");
        return null;
      }
    } catch (error) {
      recordedErrorLog("Session file to variables conversion failed: ", error);
    }
  }

  const handleOpen = () => {
    fileInputRef.current.click();
  };

  const processFiles = async (files) => {
    // const readFile = (file) => {
    //   return new Promise((resolve, reject) => {
    //     const reader = new FileReader();
    //     reader.onload = (event) => {
    //       const fileData = {
    //         name: file.name,
    //         size: file.size,
    //         type: file.type,
    //         lastModified: file.lastModified,
    //         content: event.target.result,
    //       };
    //       resolve(fileData);
    //     };
    //     reader.onerror = () => {
    //       reader.abort();
    //       reject(new Error("Error reading the file."));
    //     };
    //     reader.readAsText(file);
    //   });
    // };

    try {
      const filesData = await Promise.all(
        files.map((file) => simpleReadFile(file))
      );
      const retrievedVariables = convertSessionFileToVariables(
        filesData[0].content
      );

      if (
        (hasProperty(retrievedVariables, "dashboard"),
        hasProperty(retrievedVariables, "graph"))
      ) {
        setDashboardVariables(retrievedVariables.dashboard);
        setGraphVariables(retrievedVariables.graph);
      } else {
        throw new Error("Could not process the file");
      }
    } catch (error) {
      console.log("ERROR FILE PROCESS: ", error);
      limitedToast("Error loading file.");
      generateWarningObject(
        "Selected file could not be processed",
        2,
        setWarnings,
        setNewWarningCount
      );
    } finally {
      if (fileInputRef.current) {
        fileInputRef.current.value = "";
      }
    }
  };

  const handleUnitChange = (e) => {
    try {
      const value = e.target.value;
      const unit = units.filter((u) => u.value === value)[0];
      setXUnit(unit);
    } catch (error) {
      recordedErrorLog("Unit change handler failure: ", error);
    }
  };

  const handleDemo = () => {
    setDemoOpen((old) => !old);
  };

  return (
    <AppBarStyle
      position="static"
      sx={{ backgroundColor: "#d7e7f5", borderBottom: "1px solid #2D446A" }}
    >
      <Toolbar sx={{ flexGrow: 1 }} className="topBarContainer">
        <img src={logo} alt="Reffit logo" className="appBarLogo" />

        {isFitOngoing || isLoopOngoing ? (
          <TextLoader
            text={`Processing ${
              isLoopOngoing ? "loop" : "fit"
            }, functionality reduced.`}
          />
        ) : (
          <></>
        )}

        <div className="buttonsAndMenuContainer">
          {warnings.length > 0 ? (
            <div
              className="alertsContainer"
              onClick={() => handleWarningIconClick()}
            >
              {newWarningCount > 0 ? (
                <div className="newWarnCount">
                  {newWarningCount > 9 ? "9+" : newWarningCount}
                </div>
              ) : (
                <></>
              )}
              <img
                src={alert}
                alt="Alert Icon"
                className="alertIcon"
                ref={warningsRef}
              />
            </div>
          ) : (
            <></>
          )}

          <Modal
            isOpen={modalIsOpen}
            onRequestClose={handleCloseModal}
            shouldCloseOnOverlayClick={false}
            contentLabel="Warning Modal"
            appElement={warningsRef.current}
            overlayClassName="warningModalOverlay"
            style={{
              content: {
                width: modalPlaceAndSize.width,
                height: modalPlaceAndSize.height,
                top: modalPlaceAndSize.top,
                right: modalPlaceAndSize.right,
                left: modalPlaceAndSize.left,
                zIndex: "9999",
              },
            }}
          >
            <div className="warningList">
              {warningsToDisplay}
              <img
                src={close}
                alt="Close Button"
                className="closeIcon"
                onClick={() => handleCloseModal()}
              />
              <div className="clearAllButton" onClick={() => handleClearAll()}>
                Clear All
              </div>
            </div>
          </Modal>
          <Button
            variant="contained"
            sx={{
              backgroundColor: "#145d9d",
              borderColor: "#145d9d",
              borderStyle: "solid",
              borderWidth: 2,
              color: "white",
              ":hover": {
                color: "white",
                backgroundColor: "#145d9d",
              },
              fontSize: "0.8em",
              marginRight: "4vw",
            }}
            size="small"
            onClick={() => setAddGraphModalOpen((old) => !old)}
            id="add-graph-button"
          >
            Add Graph
          </Button>
          <Button
            variant="contained"
            sx={{
              backgroundColor: demoOpen ? "#145d9d" : "white",
              borderColor: "#145d9d",
              borderStyle: "solid",
              borderWidth: 2,
              color: demoOpen ? "white" : "#145d9d",
              marginRight: 1,
              ":hover": {
                color: "white",
                backgroundColor: "#145d9d",
              },
              fontSize: "0.8em",
            }}
            size="small"
            onClick={() => handleDemo()}
            id="demo-switch"
          >
            Demo
          </Button>
          <Button
            variant="contained"
            sx={{
              backgroundColor: "white",
              borderColor: "#145d9d",
              borderStyle: "solid",
              borderWidth: 2,
              color: "#145d9d",
              marginRight: 1,
              ":hover": {
                color: "white",
                backgroundColor: "#145d9d",
              },
              fontSize: "0.8em",
            }}
            size="small"
            onClick={() => handleOpen()}
          >
            Open
          </Button>
          <input
            ref={fileInputRef}
            type="file"
            accept=".json,.txt"
            onChange={(e) => handleFileChange(e, ["json", "txt"], processFiles)}
            style={{ display: "none" }}
          />
          <Button
            variant="contained"
            sx={{
              backgroundColor: "white",
              borderColor: "#145d9d",
              borderStyle: "solid",
              borderWidth: 2,
              color: "#145d9d",
              ":hover": {
                color: "white",
                backgroundColor: "#145d9d",
              },
              fontSize: "0.8em",
            }}
            size="small"
            onClick={() => handleSessionSaveStart()}
          >
            Save
          </Button>
          <IconButton
            color="inherit"
            id="app-settings-button"
            aria-controls={open ? "basic-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
            onClick={() => handleSettingsClick()}
            ref={settingsRef}
          >
            <SettingsIcon sx={{ color: "#2D446A", fontSize: 30 }}>
              <NotificationsIcon />
            </SettingsIcon>
          </IconButton>
          <Modal
            isOpen={settingModalOpen}
            onRequestClose={handleCloseSettingsModal}
            shouldCloseOnOverlayClick={true}
            appElement={settingsRef.current}
            id="appSettingsWindow"
            style={{
              content: {
                width: "300px",
                height: "300px",
                top: "3vw",
                right: "3vw",
                left: "auto",
                zIndex: "9999",
              },
              overlay: {
                zIndex: "9999",
              },
            }}
          >
            <div className="settingsModal">
              <div className="settings">
                <div className="title">General App settings:</div>
                <div className="setting">
                  <div className="unitSelectorLabel">x-data unit: </div>
                  <div className="xUnitSelectContainer">
                    <Select
                      MenuProps={{
                        container: () =>
                          document.getElementById("appSettingsWindow"),
                      }}
                      value={xUnit.value}
                      onChange={handleUnitChange}
                      className="x_unit_select"
                    >
                      {units.map(({ name, value }) => (
                        <MenuItem key={name} value={value}>
                          {name}
                        </MenuItem>
                      ))}
                    </Select>
                  </div>
                </div>
              </div>
              <div className="buttons">
                <Button
                  variant="outlined"
                  onClick={() => handleSessionClear()}
                  id="logout-button"
                >
                  Clear Session
                </Button>
                <Button
                  variant="outlined"
                  onClick={() => handleLogout()}
                  id="logout-button"
                >
                  Logout
                </Button>
              </div>
            </div>
          </Modal>
          {/* Logout menu */}
          {/* <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
              "aria-labelledby": "basic-button",
            }}
          >
            <Link to="/" style={{ textDecorationLine: "none" }}>
              <MenuItem
                onClick={() => handleLogout()}
                sx={{ color: "#145d9d" }}
              >
                Logout
              </MenuItem>
            </Link>
          </Menu> */}
          <AccountCircleIcon
            className="userIcon"
            id="user-profile-home-icon"
            onClick={() => handleUserIconClick()}
          />
        </div>
      </Toolbar>
    </AppBarStyle>
  );
};

export default AppBar;
