import React, {
  createContext,
  useState,
  useContext,
  useRef,
  useEffect,
} from "react";
import useWebSocket from "react-use-websocket";
import { AuthContext } from "./AuthContext";
import { DashboardContext } from "./DashboardContext";
import { GeneralContext } from "./GeneralContext";
import { djb2Hash, generateWarningObject, hasProperty } from "../utils/helpers";

export const WebSocketContext = createContext();

const DELAY_TIME_IN = 5; // 5 milliseconds
const DELAY_TIME_OUT = 1; // 5 milliseconds
const MAX_RECONNECT_ATTEMPTS = 2; // Maximum reconnection attempts + 1 (calculated from 0)

const WebSocketContextProvider = (props) => {
  const [authSent, setAuthSent] = useState(false);
  //web socket is in a global context, so that it's opened and closed only once
  const { currentUser, isAuthReady, baseBE_URL } = useContext(AuthContext);
  const { setWarnings, setNewWarningCount } = useContext(DashboardContext);
  const { limitedToast } = useContext(GeneralContext);
  const [isFitOngoing, setIsFitOngoing] = useState(false);
  const [isLoopOngoing, setIsLoopOngoing] = useState(false);
  const [reconnectedAfterFail, setReconnectedAfterFail] = useState(false);
  const reconnectAttempts = useRef(0);
  const requestIndex = useRef(0);
  const requestDict = useRef({});
  const messageQueue = useRef([]); // Queue for outgoing messages
  // Initialize a message queue to store incoming messages
  const incomingMessageQueue = useRef([]);
  // Flag to indicate if the system is currently processing messages
  const isProcessing = useRef(false);
  const isProcessingOut = useRef(false);
  const websocketFailed = useRef(false);

  const [delayedLastJsonMessage, setDelayedLastJsonMessage] = useState(null);
  const [delayedLastMessage, setDelayedLastMessage] = useState(null);
  // const [timer, setTimer] = useState(null);

  // const timeQueue = useRef([]);
  const firstConnection = useRef(true);

  const idOfUser =
    isAuthReady && currentUser && currentUser.id ? currentUser.id : -1;

  // const webSocURL = isAuthReady ? `${baseBE_URL}/${idOfUser}` : null;
  const webSocURL =
    isAuthReady && currentUser && currentUser.id
      ? `${baseBE_URL}/${idOfUser}`
      : null;

  // Function to process the queue of messages one by one
  // const processQueue = () => {
  //   if (isProcessing.current || incomingMessageQueue.current.length === 0) {
  //     return; // If already processing or no messages, return
  //   }

  //   // Set processing flag to true to block other messages from starting until done
  //   isProcessing.current = true;

  //   // Process each message in the queue
  //   const message = incomingMessageQueue.current.shift(); // Get the first message in the queue

  //   if (message) {
  //     try {
  //       const parsedMessage = JSON.parse(message);
  //       setDelayedLastJsonMessage(parsedMessage);
  //     } catch (_) {
  //       // Not a JSON message
  //       setDelayedLastMessage(message);
  //     }

  //     // Once processed, check if there are more messages to process
  //     setTimeout(() => {
  //       isProcessing.current = false; // Done processing, allow the next message
  //       processQueue(); // Check for any other messages in the queue
  //     }, DELAY_TIME); // Delay between message processing
  //   }
  // };

  useEffect(() => {
    if (reconnectedAfterFail && authSent) {
      const result = getMostRecentDetails();
      if (!result) return; // No recent details available

      const { id, details: mostRecentDetails } = result;

      // Ensure reconnectAttempts is defined
      if (typeof mostRecentDetails.reconnectAttempts === "undefined") {
        mostRecentDetails.reconnectAttempts = 0;
      }

      if (mostRecentDetails.reconnectAttempts === 0) {
        // Increment the reconnect attempt count
        mostRecentDetails.reconnectAttempts += 1;

        // Update requestDict with the incremented reconnectAttempts
        requestDict.current[id] = { ...mostRecentDetails };

        // Attempt to re-send the failed payload
        sendJsonMessage(mostRecentDetails.payloadSent);

        if (mostRecentDetails.type === "fit-message") {
          setIsFitOngoing(true);
        }
        if (mostRecentDetails.type === "loop-request") {
          setIsLoopOngoing(true);
        }
      } else {
        console.error(
          "WebSocket communication with BE failed after retrying last payload."
        );
        limitedToast("WebSocket communication with BE failed.");
        generateWarningObject(
          `The connection to the server was lost, last message was not responded to. Please refresh the page. If error persists, please contact support.`,
          2,
          setWarnings,
          setNewWarningCount
        );
      }
    }
  }, [authSent, reconnectedAfterFail]);

  // On receiving a message from WebSocket, push it into the queue
  const handleMessage = (event) => {
    const message = event.data;
    if (message) {
      let isJson = true;
      let parsedMessage = null;
      try {
        parsedMessage = JSON.parse(message);
      } catch (_) {
        // Not a JSON message
        isJson = false;
      }

      if (isJson) {
        isProcessingOut.current = false; // Reset processing flag
        processOutgoingMessageQueue();

        const { requestID } = parsedMessage;

        // Check if the requestID is in the requestDict
        if (requestDict.current[requestID]) {
          // Add the incoming message to the queue and process it
          incomingMessageQueue.current.push(message); // Add to incoming queue
          processIncomingMessageQueue(); // Process the incoming queue
        }
      } else {
        setDelayedLastMessage(message);
        incomingMessageQueue.current.push(message); // Add to incoming queue
        processIncomingMessageQueue(); // Process the incoming queue
      }
    }
  };

  // UNCOMMEN IF MESSAGE QUEUE NEEDS CLEAN UP:
  // const clearResponseMessages = () => {
  //   messageQueue.current = []; // Clear the queue
  //   isProcessing.current = false; // Reset the processing flag
  //   setLastProcessedIndex(-1);
  //   setDelayedLastJsonMessage(null);
  //   setDelayedLastMessage(null);
  // };

  function resetWebSocketContext() {
    setAuthSent(false);
    setIsFitOngoing(false);
    setIsLoopOngoing(false);
    reconnectAttempts.current = 0;
    requestIndex.current = 0;
    requestDict.current = {};
    messageQueue.current = [];
    incomingMessageQueue.current = [];
    isProcessing.current = false;
    isProcessingOut.current = false;
    firstConnection.current = true;
    setDelayedLastJsonMessage(null);
    setDelayedLastMessage(null);

    if (websocketFailed.current) {
      setReconnectedAfterFail(true);
      websocketFailed.current = false;
    }
  }

  // const processNextMessageInQueue = () => {
  //   if (messageQueue.current.length > 0 && !isProcessing.current) {
  //     const nextMessage = messageQueue.current.shift(); // Get the next message in the queue
  //     if (nextMessage) {
  //       // Send the next message in queue
  //       checkedSendJsonMessage(nextMessage.message, nextMessage.details);
  //     }
  //   }
  // };

  const processIncomingMessageQueue = () => {
    if (incomingMessageQueue.current.length > 0 && !isProcessing.current) {
      const nextMessage = incomingMessageQueue.current.shift(); // Get the next message from the queue
      if (nextMessage) {
        // Process the next incoming message
        try {
          const parsedMessage = JSON.parse(nextMessage);
          setDelayedLastJsonMessage(parsedMessage);
        } catch (_) {
          // Handle non-JSON messages
          setDelayedLastMessage(nextMessage);
        }

        // Once processed, check for the next message
        setTimeout(() => {
          isProcessing.current = false; // Allow next message to be processed
          processIncomingMessageQueue(); // Process the next message in the queue
        }, DELAY_TIME_IN);
      }
    }
  };

  function getMostRecentDetails() {
    const entries = Object.entries(requestDict.current);
    if (entries.length === 0) {
      return null;
    }

    let [mostRecentId, mostRecentDetails] = entries[0];
    for (let i = 1; i < entries.length; i++) {
      const [id, details] = entries[i];
      if (details.timestamp > mostRecentDetails.timestamp) {
        mostRecentId = id;
        mostRecentDetails = details;
      }
    }

    return { id: mostRecentId, details: mostRecentDetails };
  }

  // This function is called when the WebSocket closes unexpectedly
  const handleClose = (event) => {
    // We set a variable here to show that websocket connection closed unexpectedly
    websocketFailed.current = true;

    setIsFitOngoing(false);
    setIsLoopOngoing(false);

    console.warn("WebSocket connection lost.", event);
    // limitedToast("The WebSocket connection has been lost.");
    generateWarningObject(
      `The connection to the server was lost. Attempting to reconnect...`,
      1,
      setWarnings,
      setNewWarningCount
    );
  };

  const {
    sendMessage,
    sendJsonMessage,
    // lastMessage,
    // lastJsonMessage,
    readyState,
  } = useWebSocket(webSocURL, {
    shouldReconnect: () => {
      if (reconnectAttempts.current < MAX_RECONNECT_ATTEMPTS) {
        reconnectAttempts.current = reconnectAttempts.current + 1;
        return true;
      }
      limitedToast(`Server connection issue.`);
      generateWarningObject(
        `The connection to the server was lost and reconnects were unsuccessful.` +
          ` Please save your work and try to log in again. If that doesn't work, contact support: contact@speqqle.com`,
        2,
        setWarnings,
        setNewWarningCount
      );
      return false;
    }, // function can get param 'closeEvent'
    share: true, // Share a single WebSocket connection across hooks
    // filter: () => isAuthReady,
    filter: () => isAuthReady && currentUser && currentUser.id,
    onMessage: handleMessage, // Use the queue-based handler for messages
    onOpen: () => {
      if (firstConnection.current) {
        // This is a first connection to the websocket, so we do nothing but mark that the first connection was done
        firstConnection.current = false;
      } else {
        // This is a reconnection, so we need to reset some variables, namely the ones responsible for websocket
        // authentification
        resetWebSocketContext();
        reconnectAttempts.current = 0;
      }
    },
    onClose: handleClose,
  });

  function generateRequestId() {
    const stringToHash = `${Date.now()}|${requestIndex.current}|${
      currentUser.id
    }`;

    const hashedID = djb2Hash(stringToHash);

    requestIndex.current = requestIndex.current + 1;

    return hashedID;
  }

  function regenerateId(currentId) {
    const LIMIT = 10;

    for (let i = 0; i < LIMIT; i++) {
      let newId = generateRequestId();

      if (newId !== currentId) {
        return newId;
      }
    }

    limitedToast(`Communication issue`);
    generateWarningObject(
      `Issue with producing request id, please report to support.`,
      2,
      setWarnings,
      setNewWarningCount
    );

    return null;
  }

  const checkedSendJsonMessage = (message, details = {}) => {
    // If the fit is ongoing or the message has a "stopfit" property, send it immediately
    if (hasProperty(message, "stopfit") || hasProperty(message, "stoploop")) {
      sendJsonMessage(message);
    } else if (!isFitOngoing && !isLoopOngoing) {
      let idToSend = -1;
      if (details && hasProperty(details, "requestID")) {
        idToSend = details.requestID;
      } else {
        idToSend = generateRequestId();

        if (hasProperty(requestDict.current, idToSend)) {
          const regeneratedId = regenerateId(idToSend);
          if (regeneratedId === null) {
            return;
          }
          idToSend = regeneratedId;
        }
      }

      const payloadInQ = {
        requestID: idToSend,
        ...message,
      };

      let detailsToPut = {};

      if (
        details &&
        hasProperty(details, "reconnectAttempts") &&
        details.reconnectAttempts > 0
      ) {
        detailsToPut = { ...details };
      } else {
        if (details) {
          detailsToPut = { ...details };
        }

        detailsToPut = {
          ...detailsToPut,
          requestID: idToSend,
          timestamp: Date.now(),
          reconnectAttempts: 0,
          payloadSent: message,
        };
      }

      requestDict.current[idToSend] = detailsToPut;

      messageQueue.current.push(payloadInQ);

      processOutgoingMessageQueue();
      // sendJsonMessage({
      //   requestID: idToSend,
      //   ...message,
      // });
    } else {
      limitedToast(
        `The ${
          isFitOngoing ? "fit" : "loop"
        } is still processing, all other requests are not allowed during this period.`
      );
      generateWarningObject(
        `The ${
          isFitOngoing ? "fit" : "loop"
        } is still processing, all other requests are not allowed during this period.`,
        2,
        setWarnings,
        setNewWarningCount
      );
    }
  };

  const processOutgoingMessageQueue = () => {
    if (messageQueue.current.length > 0 && !isProcessingOut.current) {
      isProcessingOut.current = true;

      // Get the next message from the queue
      const nextMessage = messageQueue.current.shift();

      if (nextMessage) {
        sendJsonMessage(nextMessage);

        // Set a delay before allowing the next message to be sent
        setTimeout(() => {
          processOutgoingMessageQueue(); // Process the next message in the queue
        }, DELAY_TIME_OUT); // Ensure at least 5ms delay between messages
      }
    }
  };

  function retrieveRequestData(id) {
    if (hasProperty(requestDict.current, id)) {
      return requestDict.current[id];
    }
    return null;
  }

  function deleteRequestDataEntry(id) {
    if (hasProperty(requestDict.current, id)) {
      delete requestDict.current[id];
    }
  }

  const value = {
    lastJsonMessage: delayedLastJsonMessage,
    lastMessage: delayedLastMessage,
    sendJsonMessage: checkedSendJsonMessage,
    sendMessage,
    readyState,
    authSent,
    setAuthSent,
    resetWebSocketContext,
    isFitOngoing,
    setIsFitOngoing,
    retrieveRequestData,
    deleteRequestDataEntry,
    isLoopOngoing,
    setIsLoopOngoing,
  };

  return (
    <WebSocketContext.Provider value={value}>
      {props.children}
    </WebSocketContext.Provider>
  );
};

export default WebSocketContextProvider;
