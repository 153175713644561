import React, { useState, useEffect, useContext } from "react";
import { DashboardContext } from "../../../context/DashboardContext";
import { GeneralContext } from "../../../context/GeneralContext";
import ParameterSelector from "./ParameterSelector";
import { getModelById } from "../../leftSide/Models/modelLogic";
import "./loop.scss";

function ParameterSelectorWindow(props) {
  const { modelList } = props;
  const { recordedErrorLog } = useContext(GeneralContext);
  const { modelData } = useContext(DashboardContext);
  const [displayableModels, setDisplayableModels] = useState([]);

  useEffect(() => {
    try {
      if (modelList !== undefined) {
        const listOfModels = [];
        for (let i = 0; i < modelList.length; i++) {
          const modelEntry = modelList[i];

          const modelToAdd = getModelById(modelEntry.FE_ID, modelData);

          listOfModels.push(modelToAdd);
        }

        setDisplayableModels(listOfModels);
      }
    } catch (error) {
      recordedErrorLog(
        "Error producing model list for parameter selection: ",
        error
      );
    }
  }, [modelList]);

  return (
    <div className="parameterSelectorWindow">
      {displayableModels !== undefined ? (
        displayableModels.map((model) => (
          <ParameterSelector model={model} key={model.FE_ID} />
        ))
      ) : (
        <></>
      )}
    </div>
  );
}

export default ParameterSelectorWindow;
