import React, { useContext, useState, useEffect } from "react";
import { DashboardContext } from "../../context/DashboardContext";
import "./windowSelector.scss";

function WindowSelector() {
  const {
    // demoOpen,
    // setDemoOpen,
    dataOpen,
    setDataOpen,
    modelsOpen,
    setModelsOpen,
    parametersOpen,
    setParametersOpen,
    fitOpen,
    setFitOpen,
    resultsOpen,
    setResultsOpen,
    modelData,
    loopOpen,
    setLoopOpen,
    outputsOpen,
    setOutputsOpen,
  } = useContext(DashboardContext);
  const [listForGauges, setListForGauges] = useState([]);

  useEffect(() => {
    const filteredList = modelData.filter((model) => model.reffitID === -113); //We filter only Raman
    setListForGauges(filteredList);
  }, [modelData]);

  const handleLoopClick = () => {
    if (!loopOpen) {
      setLoopOpen(true);
      setOutputsOpen(false);
      setDataOpen(false);
      setModelsOpen(false);
      setParametersOpen(false);
      setFitOpen(false);
      setResultsOpen(false);
    } else {
      setLoopOpen((old) => !old);
    }
  };

  const handleOutputsClick = () => {
    if (!outputsOpen && loopOpen) {
      setLoopOpen(false);
      setOutputsOpen(true);
    } else {
      setOutputsOpen((old) => !old);
    }
  };

  const handleDataOpen = () => {
    if (!dataOpen && loopOpen) {
      setLoopOpen(false);
      setDataOpen(true);
    } else {
      setDataOpen((old) => !old);
    }
  };

  const handleModelsOpen = () => {
    if (!modelsOpen && loopOpen) {
      setLoopOpen(false);
      setModelsOpen(true);
    } else {
      setModelsOpen((old) => !old);
    }
  };

  const handleParametersOpen = () => {
    if (modelData.length > 0) {
      if (!parametersOpen && loopOpen) {
        setLoopOpen(false);
        setParametersOpen(true);
      } else {
        setParametersOpen((old) => !old);
      }
    } else {
      setParametersOpen(false);
    }
  };

  const handleFitOpen = () => {
    if (!fitOpen && loopOpen) {
      setLoopOpen(false);
      setFitOpen(true);
    } else {
      setFitOpen((old) => !old);
    }
  };

  const handleResultsOpen = () => {
    if (listForGauges.length > 0) {
      if (!resultsOpen && loopOpen) {
        setLoopOpen(false);
        setResultsOpen(true);
      } else {
        setResultsOpen((old) => !old);
      }
    } else {
      setResultsOpen(false);
    }
  };

  return (
    <div className="windowSelector">
      <div className="switchPlatter" />
      <div className="switches">
        {/* <div
          className={`switch${demoOpen ? " open" : ""}`}
          onClick={() => setDemoOpen((old) => !old)}
          id="demo-switch"
        >
          Demo
        </div> */}
        <div
          className={`switch${dataOpen ? " open" : ""}`}
          onClick={() => handleDataOpen()}
          id="data-switch"
        >
          Data
        </div>
        <div
          className={`switch${modelsOpen ? " open" : ""}`}
          onClick={() => handleModelsOpen()}
          id="models-switch"
        >
          Experiments
        </div>
        <div
          className={`switch${parametersOpen ? " open" : ""}${
            modelData.length > 0 ? "" : " disabled"
          }`}
          onClick={() => handleParametersOpen()}
          id="parameters-switch"
        >
          Parameters
        </div>
        <div
          className={`switch${fitOpen ? " open" : ""}`}
          onClick={() => handleFitOpen()}
          id="fit-switch"
        >
          Fit
        </div>
        <div
          className={`switch${resultsOpen ? " open" : ""}${
            listForGauges.length > 0 ? "" : " disabled"
          }`}
          onClick={() => handleResultsOpen()}
          id="fit-switch"
        >
          Results
        </div>
        <div
          className={`switch${outputsOpen ? " open" : ""}`}
          onClick={() => handleOutputsClick()}
          id="fit-switch"
        >
          Outputs
        </div>
        <div
          className={`switch${loopOpen ? " open" : ""}`}
          onClick={() => handleLoopClick()}
          id="fit-switch"
        >
          Loop
        </div>
      </div>
    </div>
  );
}

export default WindowSelector;
