import React, { useState, useRef, useEffect, useContext } from "react";
import { deepCopy, getModelById } from "../../leftSide/Models/modelLogic";
import {
  adjustModalPositionAndSize,
  downloadStringTxtFile,
  findBestMatchingCurve,
  getQuantityNameFromModels,
  hasProperty,
} from "../../../utils/helpers";
import Modal from "react-modal";
import { Button } from "@mui/material";
import deleteIcon from "../../../res/icons/delete.png";
import DownloadIcon from "@mui/icons-material/Download";
import "./windowComps.scss";
import ColorPicker from "./ColorPicker";
import LineStyleSelect from "./LineStyleSelect";
import LineWidthSelect from "./LineWidthSelect";
import LineModeSelect from "./LineModeSelect";
import { GeneralContext } from "../../../context/GeneralContext";

function CurvesModal(props) {
  const {
    modelData,
    curvesModalIsOpen,
    setCurvesModalIsOpen,
    reference,
    distList,
    handleDistDelete,
    selectFileList,
    selectModelList,
    graphRef,
    id,
    updateGraphPlots,
    zIndex,
  } = props;

  const { recordedErrorLog } = useContext(GeneralContext);

  const [isDistOverlayVisible, setIsDistOverlayVisible] = useState(false);
  const [colorModalOpen, setColorModalOpen] = useState(false);
  const [colorToChange, setColorToChange] = useState(null);
  const [curveToChange, setCurveToChange] = useState(null);
  const [clickCoordinates, setClickCoordinates] = useState({ x: 0, y: 0 });
  const [updatedPlots, setUpdatedPlots] = useState([]);
  const distListRef = useRef(null);
  const [curvesModalPlaceAndSize, setCurvesModalPlaceAndSize] = useState({
    top: "0",
    left: "0",
    height: "300px",
    width: "300px",
  });

  useEffect(() => {
    if (curvesModalIsOpen) {
      const modalPosition = {
        top:
          reference.current != null
            ? reference.current.getBoundingClientRect().top
            : 0,
        right: "auto",
        left:
          reference.current != null
            ? reference.current.getBoundingClientRect().left
            : 0,
      };
      const modalSize = { width: 500, height: 500 };

      const adjusted = adjustModalPositionAndSize(modalPosition, modalSize);

      setCurvesModalPlaceAndSize(adjusted);
    }
  }, [curvesModalIsOpen]);

  const handleDistDownload = (modelId, quantity) => {
    try {
      const foundModel = getModelById(modelId, modelData);
      const currentGraph = graphRef.current.find((graph) => graph.id === id);

      const currentGraphRange = currentGraph.layout.xaxis.range;

      const foundCurve = findBestMatchingCurve(
        foundModel.curves,
        currentGraphRange[0],
        currentGraphRange[1],
        quantity
      );

      if (!foundCurve.bestFound) {
        console.warn("Best curve not found in model!!");
      }

      let fileContent = `X\tY\n`;

      for (let i = 0; i < foundCurve.curve.curve.length; i++) {
        const coordSet = foundCurve.curve.curve[i];
        fileContent = fileContent + `${coordSet.x}\t${coordSet.y}\n`;
      }

      downloadStringTxtFile(fileContent, `${foundModel.displayName} - Curve`);
    } catch (error) {
      recordedErrorLog("Curve download handler failure: ", error);
    }
  };

  const handleCloseSettingsModal = () => {
    try {
      const currentGraph = graphRef.current.find((graph) => graph.id == id);
      let plotsUpdate = currentGraph.plotData;

      // HERE WE NEED TO UPDATE GRAPH LAYOUT WITH CHANGES
      if (updatedPlots.length > 0) {
        for (let i = 0; i < updatedPlots.length; i++) {
          const update = updatedPlots[i];
          const isModel = hasProperty(update, "modelId");
          if (isModel) {
            plotsUpdate = plotsUpdate.map((plot) => {
              let plotToReturn = plot;
              if (
                hasProperty(plot, "modelId") &&
                plot.modelId === update.modelId &&
                plot.quantity === update.quantity &&
                hasProperty(update, "color")
              ) {
                plotToReturn = {
                  ...plotToReturn,
                  line: { ...plotToReturn.line, color: update.color },
                };
              }
              if (
                hasProperty(plot, "modelId") &&
                plot.modelId === update.modelId &&
                plot.quantity === update.quantity &&
                hasProperty(update, "line")
              ) {
                plotToReturn = {
                  ...plotToReturn,
                  line: { ...plotToReturn.line, dash: update.line },
                };
              }
              if (
                hasProperty(plot, "modelId") &&
                plot.modelId === update.modelId &&
                plot.quantity === update.quantity &&
                hasProperty(update, "width")
              ) {
                plotToReturn = {
                  ...plotToReturn,
                  line: { ...plotToReturn.line, width: update.width },
                };
              }
              if (
                hasProperty(plot, "modelId") &&
                plot.modelId === update.modelId &&
                plot.quantity === update.quantity &&
                hasProperty(update, "mode")
              ) {
                plotToReturn = {
                  ...plotToReturn,
                  mode: update.mode,
                };
              }
              return plotToReturn;
            });
          } else {
            plotsUpdate = plotsUpdate.map((plot) => {
              let plotToReturn = plot;
              if (
                hasProperty(plot, "fileId") &&
                plot.fileId === update.fileId &&
                hasProperty(update, "color")
              ) {
                plotToReturn = {
                  ...plotToReturn,
                  line: { ...plotToReturn.line, color: update.color },
                };
              }
              if (
                hasProperty(plot, "fileId") &&
                plot.fileId === update.fileId &&
                hasProperty(update, "line")
              ) {
                plotToReturn = {
                  ...plotToReturn,
                  line: { ...plotToReturn.line, dash: update.line },
                };
              }
              if (
                hasProperty(plot, "fileId") &&
                plot.fileId === update.fileId &&
                hasProperty(update, "width")
              ) {
                plotToReturn = {
                  ...plotToReturn,
                  line: { ...plotToReturn.line, width: update.width },
                };
              }
              if (
                hasProperty(plot, "fileId") &&
                plot.fileId === update.fileId &&
                hasProperty(update, "mode")
              ) {
                plotToReturn = {
                  ...plotToReturn,
                  mode: update.mode,
                };
              }
              return plotToReturn;
            });
          }
        }
        updateGraphPlots(plotsUpdate);
      }

      setCurvesModalIsOpen(false);
      setIsDistOverlayVisible(false);
    } catch (error) {
      recordedErrorLog("Settings modal closing handler failure: ", error);
    }
  };

  const toggleDistOverlay = () => {
    setIsDistOverlayVisible(!isDistOverlayVisible);
  };

  const handleDistAdd = () => {
    toggleDistOverlay();
  };

  const handleColorClick = (currentColor, dist, e) => {
    setClickCoordinates({
      x: e.clientX,
      y: e.clientY,
    });
    setColorToChange(currentColor);
    setCurveToChange(dist);
    setColorModalOpen(true);
  };

  const handleColorModalClose = () => {
    setColorModalOpen(false);
  };

  const handleNewColorConfirm = (color) => {
    setColorModalOpen(false);
    updatePlotsWithVal({ color: color }, curveToChange);
  };

  function findCurveColor(curve, isModel) {
    try {
      if (curve === undefined) {
        return "#000";
      }

      if (updatedPlots.length === 0) {
        return curve.line.color;
      }

      if (isModel) {
        for (let i = 0; i < updatedPlots.length; i++) {
          const update = updatedPlots[i];
          if (
            hasProperty(update, "modelId") &&
            update.modelId === curve.modelId &&
            update.quantity === curve.quantity &&
            hasProperty(update, "color")
          ) {
            return update.color;
          }
        }
      } else {
        for (let i = 0; i < updatedPlots.length; i++) {
          const update = updatedPlots[i];
          if (
            hasProperty(update, "fileId") &&
            update.fileId === curve.fileId &&
            hasProperty(update, "color")
          ) {
            return update.color;
          }
        }
      }

      // Updated color not found, so we return the original color
      return curve.line.color;
    } catch (error) {
      recordedErrorLog("Curve color finder failure: ", error);
    }
  }

  const updateLine = (value, curve) => {
    updatePlotsWithVal({ line: value }, curve);
  };

  const updateWidth = (value, curve) => {
    updatePlotsWithVal({ width: value }, curve);
  };

  const updateLineMode = (value, curve) => {
    updatePlotsWithVal({ mode: value }, curve);
  };

  const updatePlotsWithVal = (valObj, curve) => {
    try {
      const plotUpdates = deepCopy(updatedPlots);
      const isModel = hasProperty(curve, "modelId");

      let replaced = false;
      for (let i = 0; i < plotUpdates.length; i++) {
        const update = plotUpdates[i];
        if (
          isModel &&
          hasProperty(update, "modelId") &&
          update.modelId === curve.modelId &&
          update.quantity === curve.quantity
        ) {
          plotUpdates[i] = {
            ...plotUpdates[i],
            ...valObj,
            modelId: curve.modelId,
          };
          replaced = true;
          break;
        } else if (
          !isModel &&
          hasProperty(update, "fileId") &&
          update.fileId === curve.fileId
        ) {
          plotUpdates[i] = {
            ...plotUpdates[i],
            ...valObj,
            fileId: curve.fileId,
          };
          replaced = true;
          break;
        }
      }

      if (!replaced) {
        if (isModel) {
          plotUpdates.push({
            ...valObj,
            modelId: curve.modelId,
            quantity: curve.quantity,
          });
        } else {
          plotUpdates.push({
            ...valObj,
            fileId: curve.fileId,
          });
        }
      }

      setUpdatedPlots(plotUpdates);
    } catch (error) {
      recordedErrorLog("Plot value updater failure: ", error);
    }
  };

  return (
    <Modal
      isOpen={curvesModalIsOpen}
      onRequestClose={handleCloseSettingsModal}
      shouldCloseOnOverlayClick={true}
      contentLabel="Model Modal"
      appElement={reference.current}
      style={{
        content: {
          width: curvesModalPlaceAndSize.width,
          height: curvesModalPlaceAndSize.height,
          top: curvesModalPlaceAndSize.top,
          left: curvesModalPlaceAndSize.left,
          right: curvesModalPlaceAndSize.right,
        },
        overlay: {
          backgroundColor: "transparent",
          zIndex: zIndex + 200,
        },
      }}
      id="grahp-curves-modal"
    >
      <div className="curvesModal">
        <div>
          <div className="distTitle">Displayed Curves:</div>
          <div
            className="distList"
            ref={distListRef}
            id="dist-list-in-curves-modal"
          >
            {distList.map((dist) => {
              const isModel = hasProperty(dist, "modelId");

              const key = isModel
                ? `model-${dist.modelId}-${dist.quantity}`
                : `file-${dist.fileId}`;

              let quantityName = isModel
                ? getQuantityNameFromModels(
                    modelData,
                    dist.modelId,
                    dist.quantity
                  )
                : "";

              const currentGraph = graphRef.current.find(
                (graph) => graph.id == id
              );

              const currentCurve = currentGraph.plotData.find((plot) => {
                if (isModel) {
                  return (
                    plot.modelId === dist.modelId &&
                    plot.quantity === dist.quantity
                  );
                } else {
                  return plot.fileId === dist.fileId;
                }
              });

              let currentLine = "solid";
              if (currentCurve && hasProperty(currentCurve.line, "dash")) {
                currentLine = currentCurve.line.dash;
              }

              let currentWidth = 2;
              if (currentCurve && hasProperty(currentCurve.line, "width")) {
                currentWidth = currentCurve.line.width;
              }

              let currentMode = "lines";
              if (currentCurve && hasProperty(currentCurve, "mode")) {
                currentMode = currentCurve.mode;
              }

              const color = findCurveColor(currentCurve, isModel);

              return (
                <div
                  key={key}
                  className="distListEntry"
                  id={dist.name + "-dist-entry"}
                >
                  {isModel ? (
                    <div className="modelNameArea">
                      <div className="modelName" id={dist.name}>
                        {dist.name}
                      </div>
                      <div className="quantityName">{quantityName}</div>
                    </div>
                  ) : (
                    <div className="distListEntryName" id={dist.name}>
                      {dist.name}
                    </div>
                  )}
                  <div
                    className="curveColorArea"
                    style={{ backgroundColor: color }}
                    onClick={(e) => handleColorClick(color, dist, e)}
                  />
                  <LineStyleSelect
                    updateLine={updateLine}
                    dist={dist}
                    defaultLine={currentLine}
                  />
                  <LineWidthSelect
                    updateWidth={updateWidth}
                    dist={dist}
                    defaultWidth={currentWidth}
                  />
                  <LineModeSelect
                    dist={dist}
                    updateLineMode={updateLineMode}
                    defaultLineMode={currentMode}
                  />
                  {isModel ? (
                    <DownloadIcon
                      className="downloadIcon"
                      onClick={() =>
                        handleDistDownload(dist.modelId, dist.quantity)
                      }
                    />
                  ) : (
                    <></>
                  )}
                  <img
                    className="distListEntryIcon"
                    src={deleteIcon}
                    alt={"detele icon"}
                    id="curve-entry-delete-button"
                    onClick={() => {
                      handleDistDelete(dist);
                    }}
                  />
                </div>
              );
            })}
          </div>
        </div>
        <div className="buttonContainer">
          <Button
            variant="contained"
            size="small"
            sx={{ m: 1 }}
            onClick={() => handleDistAdd()}
            className="distAddButton"
            id="add-curves-button-curve-modal"
          >
            Add Curves
          </Button>
        </div>
        <div className={`distOverlay ${isDistOverlayVisible ? "visible" : ""}`}>
          <div
            className="distributionsModal"
            id="add-distributions-slide-in-curves-modal"
          >
            <div className="fileDistributions">
              <div className="fileDistTitle">From Files:</div>
              <div className="fileDistList">
                {selectFileList.length > 0
                  ? selectFileList
                  : "No Files Uploaded"}
              </div>
            </div>
            <hr />
            <div
              className="modelDistributions"
              id="add-model-curves-list-curves-modal"
            >
              <div className="modelDistTitle">From Models:</div>
              <div className="modelDistList">
                {selectModelList.length > 0
                  ? selectModelList
                  : "No Models Added"}
              </div>
            </div>
          </div>
          <div className="overlayCloseContainer">
            <Button
              variant="contained"
              size="small"
              sx={{ m: 1 }}
              onClick={toggleDistOverlay}
              className="closeOverlayButton"
              id="close-curve-slide-in-button"
            >
              Close
            </Button>
          </div>
        </div>
        <Modal
          isOpen={colorModalOpen}
          onRequestClose={handleColorModalClose}
          shouldCloseOnOverlayClick={true}
          contentLabel="Model Modal"
          appElement={reference.current}
          style={{
            content: {
              width: "280px",
              height: "180px",
              top: `${clickCoordinates.y - 50}px`,
              left: `${clickCoordinates.x - 100}px`,
              right: "auto",
            },
            overlay: {
              backgroundColor: "transparent",
              zIndex: zIndex + 300,
            },
          }}
        >
          <ColorPicker
            oldColor={colorToChange}
            confirmNewColor={handleNewColorConfirm}
          />
        </Modal>
      </div>
    </Modal>
  );
}

export default CurvesModal;
