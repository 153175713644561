import React from "react";
// Default unit is cm^-1 so we need conversion functions from wavenumber to anything else

const h = 6.62607015e-34; // joule*s
const c = 299792458; // m/s
const cCentimetersPerSecond = c * 100.0;
const inverseElementaryCharge = 6.242e18;

const wavenumberToCm = (x) => 1.0 / x;
const wavenumberToHz = (x) => cCentimetersPerSecond / wavenumberToCm(x);
const wavenumberToTHz = (x) => wavenumberToHz(x) * 1e-12;
const wavenumberToNm = (x) => 1.0e7 * wavenumberToCm(x);
const wavenumberToJoules = (x) => wavenumberToHz(x) * h;
const wavenumberToEv = (x) => wavenumberToJoules(x) * inverseElementaryCharge;

//exported array of possible units
export default [
  {
    name: (
      <div>
        cm<sup>-1</sup>
      </div>
    ),
    value: "cm^-1",
    convert: (x) => x,
  },
  { name: "eV", value: "eV", convert: wavenumberToEv },
  { name: "THz", value: "hz", convert: wavenumberToTHz },
  { name: "nm", value: "nm", convert: wavenumberToNm },
  { name: "microns", value: "microns", convert: wavenumberToNm },
  { name: "ps", value: "ps" },
];
