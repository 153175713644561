import React, { createContext, useState, useRef, useEffect } from "react";
import { toast } from "react-hot-toast";
import { handleFeInterfaceErrorReport } from "../components/actionBar/errorHandlingLogic";

export const GeneralContext = createContext();

const isProduction = window.location.hostname === "lab.speqqle.com";

export const GeneralContextProvider = (props) => {
  const [broadcastMessage, setBroadcastMessage] = useState([]);
  const [activePolls, setActivePolls] = useState([]);
  const [pollToSolve, setPollToSolve] = useState(null);
  const [errorLog, setErrorLog] = useState([]);

  // Using reference here instead of state because sometimes toasts are generated faster than state can update
  const toastIds = useRef([]);
  const interfaceErrorList = useRef([]);
  const errorTimeoutRef = useRef(null);
  
  // const [isReady, setIsReady] = useState(false);
  useEffect(() => {
    const sessionErrorLog = window.sessionStorage.getItem("errorLog");
    if (sessionErrorLog) {
      setErrorLog(JSON.parse(sessionErrorLog));
    }
  }, []);

  useEffect(() => {
    window.sessionStorage.setItem("errorLog", JSON.stringify(errorLog));
  }, [errorLog]);

  function serializeError(error) {
    if (error instanceof Error) {
      return {
        name: error.name,
        message: error.message,
        stack: error.stack,
        ...error, // this will include any additional properties that might have been added to the error object
      };
    }
    return error; // if it's not an Error instance, just return it as is
  }

  function handleToastCheck() {
    if (toastIds.current.length >= 3) {
      // Remove the oldest toast when limit is reached
      const oldestToastId = toastIds.current[0];
      toast.remove(oldestToastId);
      toastIds.current = toastIds.current.slice(1);
    }
  }

  const limitedToast = (message) => {
    handleToastCheck();

    // Create a new error toast and update the list of toast IDs
    const newToastId = toast.error(message);
    toastIds.current = [...toastIds.current, newToastId];
  };

  const limitedSucessToast = (message) => {
    handleToastCheck();

    // Create a new success toast and update the list of toast IDs
    const newToastId = toast.success(message);
    toastIds.current = [...toastIds.current, newToastId];
  };

  const limitedWarningToast = (message) => {
    handleToastCheck();

    // Create a new custom warning toast and update the list of toast IDs
    const newToastId = toast(message, {
      icon: "⚠️",
    });
    toastIds.current = [...toastIds.current, newToastId];
  };

  function recordedErrorLog(message, error, needsPopUp = true) {
    if (needsPopUp) {
      limitedToast("Interface Error.");

      if (isProduction) {
        // Add the error to the list
        interfaceErrorList.current.push({
          message,
          error: serializeError(error),
        });

        // Clear the previous timeout, if any
        if (errorTimeoutRef.current) {
          clearTimeout(errorTimeoutRef.current);
        }

        // Set a new timeout to send errors after 3 seconds of inactivity
        errorTimeoutRef.current = setTimeout(() => {
          handleFeInterfaceErrorReport(interfaceErrorList.current);
          interfaceErrorList.current = []; // Clear the list after sending
        }, 3000);
      }
    }

    console.error(`${message}`, error);
    setErrorLog((old) => [
      ...old,
      { message: message, error: serializeError(error) },
    ]);
  }

  const value = {
    broadcastMessage,
    setBroadcastMessage,
    activePolls,
    setActivePolls,
    pollToSolve,
    setPollToSolve,
    limitedToast,
    limitedSucessToast,
    limitedWarningToast,
    recordedErrorLog,
    // isReady,
  };

  return (
    <GeneralContext.Provider value={value}>
      {props.children}
    </GeneralContext.Provider>
  );
};

export default GeneralContextProvider;
