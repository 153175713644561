/**
 * @category LeftSide
 * @component
 * @module LeftSide
 * @description This component is the parent component of all the other components contained in the left side
 * of the dashboard. It controls which parts are visible and which are not based on the toggle buttons.
 */
import React, { useContext } from "react";
// import ToggleButton from "../commonComponents/ToggleButton";
import Grid from "@mui/material/Grid";
import Models from "./Models/Models";
import FileUploader from "./Files/FileUploader";
import Demo from "./Demo/Demo";
import { DashboardContext } from "../../context/DashboardContext";
import ToggleDisplayModal from "../commonComponents/ToggleDisplayModal";

function LeftSide() {
  const { demoOpen, dataOpen, modelsOpen } = useContext(DashboardContext);

  return (
    <Grid item xs={2.5} style={{ maxWidth: "350px" }}>
      <div className="toggleButtonContainer">
        {/* <ToggleButton content={<Demo />} title={"Demo"} id="demo-switch" /> */}
        {demoOpen ? (
          <ToggleDisplayModal
            content={<Demo />}
            white={false}
            fitContent={false}
            noBgNoBorder={false}
          />
        ) : (
          <></>
        )}
        {dataOpen ? (
          <ToggleDisplayModal
            content={<FileUploader />}
            white={false}
            fitContent={false}
            noBgNoBorder={false}
          />
        ) : (
          <></>
        )}
        {modelsOpen ? (
          <ToggleDisplayModal
            content={<Models />}
            white={false}
            fitContent={false}
            noBgNoBorder={false}
          />
        ) : (
          <></>
        )}
        {/* <ToggleButton
          content={<FileUploader />}
          title={"Data"}
          id="data-switch"
        />
        <ToggleButton
          title={"Models"}
          content={<Models />}
          id="models-switch"
        /> */}
      </div>
    </Grid>
  );
}

export default LeftSide;
