import React, { useEffect, useContext } from "react";
import { DashboardContext } from "../../../context/DashboardContext";
import { GeneralContext } from "../../../context/GeneralContext";
import CustomGauge from "./CustomGauge";
import { getShortName, hasProperty } from "../../../utils/helpers";
import { deepCopy } from "../../leftSide/Models/modelLogic";
import "./results.scss";

function Results(props) {
  const { list } = props;
  const { gaugeDict, setGaugeDict } = useContext(DashboardContext);
  const { recordedErrorLog } = useContext(GeneralContext);

  useEffect(() => {
    if (list.length > 0) {
      let gaugeDictCopy = deepCopy(gaugeDict);
      let updated = false;

      for (let i = 0; i < list.length; i++) {
        const ramanModel = list[i];
        if (!hasProperty(gaugeDict, ramanModel.FE_ID)) {
          updated = true;
          gaugeDictCopy = {
            ...gaugeDictCopy,
            [ramanModel.FE_ID]: {
              min: 0,
              max: 500,
            },
          };
        }
      }

      if (updated) {
        setGaugeDict(gaugeDictCopy);
      }
    }
  }, [list]);

  return (
    <div className="resultsSection">
      <div className="container">
        {list.map((entry) => {
          let value = 0;
          let min = 0;
          let max = 500;
          let name = "N/A";
          try {
            name = getShortName(entry.displayName);
            value = entry.modelParams[0].value;
            if (hasProperty(gaugeDict, entry.FE_ID)) {
              min = gaugeDict[entry.FE_ID].min;
              max = gaugeDict[entry.FE_ID].max;
            }
          } catch (error) {
            recordedErrorLog(
              "There was an issue setting gauge values: ",
              error
            );
          }
          return (
            <CustomGauge
              key={entry.FE_ID}
              value={value}
              name={name}
              minValueProp={min}
              maxValueProp={max}
              size={120}
              gaugeModelID={entry.FE_ID}
            />
          );
        })}
      </div>
    </div>
  );
}

export default Results;
