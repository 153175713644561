import { authHeaders } from "../../utils/authActions";
import axios from "axios";

const formatDate = () => {
  try {
    const date = new Date();
    const pad = (n) => (n < 10 ? "0" + n : n);

    const day = pad(date.getDate());
    const month = pad(date.getMonth() + 1); // Months are zero-based
    const year = date.getFullYear();
    const hours = pad(date.getHours());
    const minutes = pad(date.getMinutes());

    return `${day}/${month}/${year} ${hours}:${minutes}`;
  } catch (_) {
    return "unretrieved";
  }
};

async function sendErrorMessage(baseUrl, token, data) {
  return axios.post(
    `${baseUrl}/api/v1/users/contact_admin/`,
    data,
    authHeaders(token)
  );
}

export function handleErrorReport(receivedError, requestID) {
  try {
    const baseUrl =
      window.sessionStorage.getItem("url") !== null
        ? JSON.parse(window.sessionStorage.getItem("url"))
        : "https://belab.speqqle.com";

    const authTokenSession = JSON.parse(
      window.sessionStorage.getItem("authToken")
    );

    const currentUserSession = window.sessionStorage.getItem("currentUser");

    let currentUserID = "unretrieved";

    if (currentUserSession !== null) {
      try {
        const parsedUser = JSON.parse(currentUserSession);

        if (Object.prototype.hasOwnProperty.call(parsedUser, "id")) {
          currentUserID = parsedUser.id;
        }
      } catch (_) {
        console.log("Current user parsing error.");
      }
    }

    if (authTokenSession !== null) {
      const constructedEmail = `BE ERROR REPORT FROM:\n
      USER ID: ${currentUserID}\n
      TIME: ${formatDate()}\n
      ERROR RECEIVED FROM THE BE: ${receivedError}\n
      REQUEST ID: ${requestID}`;

      const messagePayload = {
        email_title: "MANUAL BE ERROR REPORT",
        email_content: constructedEmail,
      };

      sendErrorMessage(baseUrl, authTokenSession, messagePayload)
        .then(() => {
          return true;
        })
        .catch((e) => {
          console.log("error: ", e);
          return false;
        });
    } else {
      return false;
    }
  } catch (_) {
    return false;
  }
}

export function handleFeInterfaceErrorReport(receivedErrorList) {
  try {
    const baseUrl =
      window.sessionStorage.getItem("url") !== null
        ? JSON.parse(window.sessionStorage.getItem("url"))
        : "https://belab.speqqle.com";

    const authTokenSession = JSON.parse(
      window.sessionStorage.getItem("authToken")
    );

    const currentUserSession = window.sessionStorage.getItem("currentUser");

    let currentUserID = "unretrieved";

    if (currentUserSession !== null) {
      try {
        const parsedUser = JSON.parse(currentUserSession);

        if (Object.prototype.hasOwnProperty.call(parsedUser, "id")) {
          currentUserID = parsedUser.id;
        }
      } catch (_) {
        console.log("Current user parsing error.");
      }
    }

    if (authTokenSession !== null) {
      const constructedEmail = `FE INTERFACE ERROR REPORT FROM:\n
      USER ID: ${currentUserID}\n
      TIME: ${formatDate()}\n
      ERROR FROM THE FE: ${JSON.stringify(receivedErrorList)}`;

      const messagePayload = {
        email_title: "AUTOMATIC FE INTERFACE ERROR REPORT",
        email_content: constructedEmail,
      };

      sendErrorMessage(baseUrl, authTokenSession, messagePayload)
        .then(() => {
          return true;
        })
        .catch((e) => {
          console.log("error: ", e);
          return false;
        });
    } else {
      return false;
    }
  } catch (_) {
    return false;
  }
}
