import React from "react";
import "./commonComponents.scss";

function ToggleDisplayModal(props) {
  const { content, white = false, fitContent, noBgNoBorder = false } = props;
  return (
    <div
      className={`toggleModal${fitContent ? " fitCont" : ""}${
        noBgNoBorder ? " noBgNoBorder" : ""
      }`}
      id="data-modal-dashboard"
      style={white ? { backgroundColor: "white" } : {}}
    >
      {content}
    </div>
  );
}

export default ToggleDisplayModal;
