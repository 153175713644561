import React, { useContext } from "react";
import info from "../../res/icons/info.png";
import warning from "../../res/icons/warning.png";
import error from "../../res/icons/error-cross.png";
import clear from "../../res/icons/delete.png";
import "./appBar.scss";
import { Button } from "@mui/material";
import { handleErrorReport } from "./errorHandlingLogic";
import { GeneralContext } from "../../context/GeneralContext";

function WarningEntry(params) {
  const { message, level, time, handleDelete, index, isReportable, requestId } =
    params;

  const { limitedToast, limitedSucessToast, recordedErrorLog } =
    useContext(GeneralContext);

  let iconToUse = null;
  let color = null;

  switch (level) {
    case 0:
      iconToUse = info;
      color = "#006eff1e";
      break;
    case 1:
      iconToUse = warning;
      color = "#ffbb001e";
      break;
    case 2:
      iconToUse = error;
      color = "#ff00001e";
      break;
    default:
      iconToUse = error;
      color = "#ff00001e";
      break;
  }

  const handleReportClick = () => {
    try {
      const success = handleErrorReport(message, requestId);

      if(success !== undefined){
        if (success) {
          limitedSucessToast("Error report successful!");
        } else {
          limitedToast("Error report failed. Please contact support.");
        }
      }
    } catch (error) {
      recordedErrorLog("Error while reporting BE Error: ", error);
    }
  };

  return (
    <div className="warningEntry" style={{ backgroundColor: color }}>
      <img src={iconToUse} alt="warning message icon" className="messageIcon" />
      <div className="messageContainer">
        <div className="clearWarningContainer">
          <img
            src={clear}
            alt="clear message button"
            className="clearWarning"
            onClick={() => handleDelete(index)}
          />
        </div>
        <div className="message">{message}</div>
        <div className="bottomPart">
          {isReportable ? (
            <Button
              variant="contained"
              onClick={() => handleReportClick()}
              className="reportButton"
              sx={{
                backgroundColor: "white",
                borderColor: "#145d9d",
                borderStyle: "solid",
                borderWidth: 1,
                color: "#145d9d",
                padding: "1px 4px",
                ":hover": {
                  color: "white",
                  backgroundColor: "#145d9d",
                },
                letterSpacing: "1px",
                fontSize: "0.8em",
              }}
              size="small"
            >
              Report
            </Button>
          ) : (
            <></>
          )}
          <div className="time">{time}</div>
        </div>
      </div>
    </div>
  );
}

export default WarningEntry;
