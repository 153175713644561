import React, { useState, useEffect, useRef, useContext } from "react";
import "./loop.scss";
import { Button } from "@mui/material";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import {
  FormattedText,
  getShortName,
  hasProperty,
} from "../../../utils/helpers";
import { DashboardContext } from "../../../context/DashboardContext";
import Modal from "react-modal";
import { deepCopy } from "../../leftSide/Models/modelLogic";

function Chi2LoopEntry(props) {
  const { term, updateTerm, model } = props;
  const { uploadedFiles } = useContext(DashboardContext);
  const [keepCuts, setKeepCuts] = useState(
    hasProperty(term, "keepCuts") ? term.keepCuts : false
  );
  const [selectableFiles, setSelectableFiles] = useState([]);
  const [selectedFiles, setSelectedFiles] = useState(
    hasProperty(term, "selectedLoopFiles") ? term.selectedLoopFiles : []
  );
  const [isFileSelectModalOpen, setIsFileSelectModalOpen] = useState(false);
  const [chi2ModelList, setChi2ModelList] = useState(
    hasProperty(term, "modelList") ? term.modelList : []
  );
  // const [autofitOpen, setAutofitOpen] = useState(false);

  const buttonRef = useRef();

  useEffect(() => {
    const list = [];
    addModelToList(model, list, 0);
    setChi2ModelList(list);
  }, [model]);

  useEffect(() => {
    setSelectableFiles(
      uploadedFiles.map((file) => {
        return { ID: file.ID, name: getShortName(file.name) };
      })
    );
  }, [uploadedFiles]);

  useEffect(() => {
    updateTerm({ ...term, modelList: chi2ModelList });
  }, [chi2ModelList]);

  useEffect(() => {
    updateTerm({ ...term, selectedLoopFiles: selectedFiles });
  }, [selectedFiles]);

  useEffect(() => {
    updateTerm({ ...term, keepCuts: keepCuts });
  }, [keepCuts]);

  const addModelToList = (modelToAdd, list, depth) => {
    if (
      !chi2ModelList.some((modelEntry) => modelEntry.FE_ID === modelToAdd.FE_ID)
    ) {
      list.push({
        name: modelToAdd.displayName,
        FE_ID: modelToAdd.FE_ID,
        depth: depth,
        option: "keep",
      });
    } else {
      const currentEntry = chi2ModelList.find(
        (modelEntry) => modelEntry.FE_ID === modelToAdd.FE_ID
      );

      list.push({
        ...currentEntry,
        name: modelToAdd.displayName,
        depth: depth,
      });
    }

    if (modelToAdd.subModels.length > 0) {
      for (let i = 0; i < modelToAdd.subModels.length; i++) {
        const subModel = modelToAdd.subModels[i];

        addModelToList(subModel, list, depth + 1);
      }
    }
  };

  const handleSelectableFileModalClose = () => {
    setIsFileSelectModalOpen(false);
  };

  const toggleSelectAll = () => {
    setSelectedFiles((prev) =>
      prev.length === selectableFiles.length
        ? []
        : selectableFiles.map((file) => ({ ID: file.ID, name: file.name }))
    );
  };

  const handleFileClick = (file, event) => {
    const { ID, name } = file;

    if (event.ctrlKey || event.metaKey) {
      setSelectedFiles((prev) =>
        prev.some((f) => f.ID === ID)
          ? prev.filter((f) => f.ID !== ID)
          : [...prev, { ID, name }]
      );
    } else if (event.shiftKey && selectedFiles.length) {
      const start = selectableFiles.findIndex(
        (f) => f.ID === selectedFiles[selectedFiles.length - 1].ID
      );
      const end = selectableFiles.findIndex((f) => f.ID === ID);
      const range = selectableFiles
        .slice(Math.min(start, end), Math.max(start, end) + 1)
        .map((f) => ({ ID: f.ID, name: f.name }));

      // Add the range to selectedFiles without removing any existing selections
      setSelectedFiles((prev) => [
        ...prev,
        ...range.filter((r) => !prev.some((f) => f.ID === r.ID)),
      ]);
    } else {
      // Single click: select only the clicked file if it’s not selected; otherwise, remove it
      setSelectedFiles((prev) =>
        prev.some((f) => f.ID === ID)
          ? prev.filter((f) => f.ID !== ID)
          : [{ ID, name }]
      );
    }
  };

  const handleModelOptionChange = (option, index) => {
    const listCopy = deepCopy(chi2ModelList);
    listCopy[index] = { ...listCopy[index], option: option };
    setChi2ModelList(listCopy);

    // if(option === "autofit"){

    // }
    // else{

    // }
  };

  return (
    <div className="chi2Term">
      <div className="dataFiles">
        <div className="chiFileName">{term.fileName}</div>
        <Button
          variant="contained"
          className="datafileLoadButton"
          onClick={() => setIsFileSelectModalOpen(true)}
          ref={buttonRef}
          style={{backgroundColor: "#145d9d"}}
        >
          Load Datafiles
        </Button>
        <div className="checkboxArea">
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  checked={keepCuts}
                  onChange={() => setKeepCuts((old) => !old)}
                />
              }
              label={"Keep same exclusion ranges"}
            />
          </FormGroup>
        </div>
        <div className="selectedFilesList">
          {selectedFiles.map((selected) => {
            return (
              <div key={selected.ID} className="selectedFile">
                {selected.name}
              </div>
            );
          })}
        </div>
      </div>
      <div className="models">
        {chi2ModelList.map((modelEntry, index) => {
          return (
            <div
              className="modelEntry"
              key={modelEntry.FE_ID}
              style={{ marginLeft: `${5 * modelEntry.depth}px` }}
            >
              <div className="name">{modelEntry.name}</div>
              <div className="buttonsArea">
                <div
                  className={`button${
                    modelEntry.option === "keep" ? " selected" : ""
                  }`}
                  onClick={() => handleModelOptionChange("keep", index)}
                >
                  Keep
                </div>
                <div
                  className={`button${
                    modelEntry.option === "revert" ? " selected" : ""
                  }`}
                  onClick={() => handleModelOptionChange("revert", index)}
                >
                  Revert
                </div>
                {/* <div
                  className={`button${
                    modelEntry.option === "autofit" ? " selected" : ""
                  }`}
                  onClick={() => handleModelOptionChange("autofit", index)}
                >
                  Autofit
                </div> */}
              </div>
            </div>
          );
        })}
      </div>
      <div className="infoPanel">
        <div className="container">
          <div className="infoEntry">
            <div className="title">Weight:</div>
            <div className="value">{term.weight}</div>
          </div>
          <div className="infoEntry column">
            <div className="title">Model Output:</div>
            <div className="value">
              <FormattedText input={term.quantityName} />
            </div>
          </div>
        </div>
      </div>
      <Modal
        isOpen={isFileSelectModalOpen}
        onRequestClose={handleSelectableFileModalClose}
        shouldCloseOnOverlayClick={true}
        contentLabel="File select modal"
        appElement={buttonRef.current}
        style={{
          content: {
            top: 0,
            left: 0,
            right: "auto",
            width: 300,
            height: 300,
            padding: 0,
          },
          overlay: {
            backgroundColor: "transparent",
            zIndex: "5000",
          },
        }}
      >
        <div className="fileSelectionModal">
          <Button onClick={toggleSelectAll} className="selectToggleButton">
            {selectedFiles.length === selectableFiles.length
              ? "Unselect All"
              : "Select All"}
          </Button>
          <ul className="fileList">
            {selectableFiles.map((file) => (
              <li
                key={file.ID}
                className={`fileItem ${
                  selectedFiles.some((selected) => selected.ID === file.ID)
                    ? "selected"
                    : ""
                }`}
                onClick={(e) => handleFileClick(file, e)}
              >
                {file.name}
              </li>
            ))}
          </ul>
        </div>
      </Modal>
    </div>
  );
}

export default Chi2LoopEntry;
