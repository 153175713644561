import React, { useState, useRef, useEffect, useContext } from "react";
import {
  useInputSize,
  isAllowedNum,
  generateWarningObject,
  isDeepEqual,
  hasProperty,
} from "../../../utils/helpers";
import { DashboardContext } from "../../../context/DashboardContext";
import { GeneralContext } from "../../../context/GeneralContext";
import { isCellOrStickyWindow } from "./parameterLogic";
import { deepCopy } from "../../leftSide/Models/modelLogic";
import { getGroupColor } from "../../../theme/groupingColors";
import useUpdatedRef from "../../../utils/hooks/useUpdatedRef";
import ReactSlider from "react-slider";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import MaxMinConfirmationPopUp from "./MaxMinConfirmationPopUp";
import Decimal from "decimal.js";
import "./parameters.scss";

function StickyParameterWindow(props) {
  const {
    modelIndexes,
    getModel,
    cell,
    rec,
    changeModel,
    closeStickyWindow,
    valueUpdate,
    setValueUpdate,
    fixedUpdate,
    setFixedUpdate,
    tableIndex,
    fitOpen,
  } = props;
  const { setWarnings, setNewWarningCount, valueGroups, setValueGroups } =
    useContext(DashboardContext);
  const { limitedToast, recordedErrorLog } = useContext(GeneralContext);
  const [isDefaultFixed, setIsDefaultFixed] = useState(false);
  const [sliderValue, setSliderValue] = useState(50);
  const [baseValue, setBaseValue] = useState(cell.value); // BASE VALUE FOR SLIDER PERCENTAGE CALC
  const [group, setGroup] = useState("");
  const localGroups = useRef(valueGroups);
  const removalNeeded = useRef(false);
  const closeTheWindow = useRef(false);
  const stickyWindowRef = useRef();
  const isSliderMoving = useRef(false);
  const updateFromTable = useRef(false);

  const [valueState, value, setValue] = useUpdatedRef(cell.value);
  const [hardMaxState, hardMax, setHardMax] = useUpdatedRef(0);
  const [hardMaxTemp, hardMaxTempRef, setHardMaxTemp] = useUpdatedRef("");
  const [hardMinState, hardMin, setHardMin] = useUpdatedRef(0);
  const [hardMinTemp, hardMinTempRef, setHardMinTemp] = useUpdatedRef("");
  const [isFixedState, isFixed, setIsFixed] = useUpdatedRef(undefined);

  const [tempValue, setTempValue] = useState(cell.value);
  const isApplyUpdatingRef = useRef(false);
  const isApplyUpdatePendingRef = useRef(false);
  const newValPending = useRef();
  const newHMaxPending = useRef();
  const newHMinPending = useRef();

  // Confirmation Modal variables
  const [isConfirmOpen, setIsConfirmOpen] = useState(false);
  const [isMin, setIsMin] = useState(false);
  const [valueToSet, setValueToSet] = useState(null);
  const [valueForReset, setValueForReset] = useState(null);

  useEffect(() => {
    if (valueUpdate !== null) {
      setTempValue(valueUpdate);
      setValue(valueUpdate);
      setBaseValue(valueUpdate);
      setValueUpdate(null);
      updateFromTable.current = true;
    }
  }, [valueUpdate]);

  // This use Effect updates base slider value once the value changes by non slider updates
  useEffect(() => {
    if (value !== baseValue && !isSliderMoving.current) {
      setBaseValue(value.current);
    }
  }, [valueState]);

  useEffect(() => {
    if (fixedUpdate !== null) {
      // This check is done because it is possible to change fixed value directly in the table without changing the sticky
      // parameter window cell
      if (
        rec === fixedUpdate.rec &&
        fixedUpdate.cell.column.id === cell.column.id &&
        fixedUpdate.cell.row.id === cell.row.id
      ) {
        setIsFixed(fixedUpdate.value);
      }
      setFixedUpdate(null);
    }
  }, [fixedUpdate]);

  useEffect(() => {
    try {
      const model = getModel(modelIndexes);
      const rowIndex = cell.row.index;
      const columnId = cell.column.id;
      let hardMinFromModel = "";
      let hardMaxFromModel = "";

      let parameter = null;
      if (rec) {
        parameter = model.recParams[rowIndex].find(
          (param) => param.name === columnId
        );
      } else {
        parameter = model.modelParams.find(
          (param) =>
            param.name === columnId && param.displaytable === tableIndex
        );
      }

      if (hasProperty(parameter, "hardMax")) {
        hardMaxFromModel = parameter.hardMax;
      }
      if (hasProperty(parameter, "hardMin")) {
        hardMinFromModel = parameter.hardMin;
      }

      setTempValue(cell.value);
      setValue(cell.value);
      setBaseValue(cell.value);
      setHardMax(hardMaxFromModel);
      setHardMaxTemp("");
      setHardMin(hardMinFromModel);
      setHardMinTemp("");
    } catch (error) {
      recordedErrorLog("Error in cell change useEffect: ", error);
    }
  }, [cell]);

  const handleApply = (newValue, newHardMax, newHardMin) => {
    try {
      if (!isApplyUpdatingRef.current) {
        isApplyUpdatingRef.current = true;
        isApplyUpdatePendingRef.current = false;
        setTimeout(() => {
          isApplyUpdatingRef.current = false;
          setValue(newValue);
          setHardMax(newHardMax);
          setHardMin(newHardMin);
          if (isApplyUpdatePendingRef.current) {
            isApplyUpdatePendingRef.current = false;
            handleApply(
              newValPending.current,
              newHMaxPending.current,
              newHMinPending.current
            );
          }
        }, 100);
      } else {
        isApplyUpdatePendingRef.current = true;
        newValPending.current = newValue;
        newHMaxPending.current = newHardMax;
        newHMinPending.current = newHardMin;
      }
    } catch (error) {
      recordedErrorLog("Apply handler failure: ", error);
    }
  };

  const valueInputSize = useInputSize(
    tempValue !== undefined ? tempValue : value.current
  );
  const hmaxInputSize = useInputSize(hardMaxTemp);
  const hminInputSize = useInputSize(hardMinTemp);

  useEffect(() => {
    try {
      localGroups.current = valueGroups;
      if (
        group !== "" &&
        valueGroups[group - 1].fixed !== null &&
        valueGroups[group - 1].fixed !== isFixed.current
      ) {
        setIsFixed(valueGroups[group - 1].fixed);
      }
    } catch (error) {
      recordedErrorLog("Value groups 'fixed' check has failed: ", error);
    }
  }, [valueGroups]);

  useEffect(() => {
    try {
      if (group !== "") {
        for (let i = 0; i < localGroups.current.length; i++) {
          const valGroup = localGroups.current[i];

          if (valGroup.groupNumber == group) {
            if (valGroup.value != null) {
              value.current = valGroup.value;
              setValue(valGroup.value);
              setTempValue(valGroup.value);
              hardMax.current =
                valGroup.hardMax != null ? valGroup.hardMax : "";
              setHardMaxTemp(valGroup.hardMax != null ? valGroup.hardMax : "");
              hardMin.current =
                valGroup.hardMin != null ? valGroup.hardMin : "";
              setHardMinTemp(valGroup.hardMin != null ? valGroup.hardMin : "");
              if (valGroup.fixed != null) {
                setIsFixed(valGroup.fixed);
              }
            }
            break;
          }
        }
      }
    } catch (error) {
      recordedErrorLog("Group changes useEffect failure: ", error);
    }
  }, [group]);

  useEffect(() => {
    try {
      if (!updateFromTable.current) {
        const model = getModel(modelIndexes);
        const modelCopy = deepCopy(model);
        const rowIndex = cell.row.index;
        const columnId = cell.column.id;
        const valGroupsCopy = deepCopy(localGroups.current);
        let groupsNeedsUpdate = false;

        if (isFixed.current != undefined) {
          if (rec) {
            let recParams = model.recParams[rowIndex];
            recParams = modelCopy.recParams[rowIndex].map((param, index) => {
              if (param.name === columnId) {
                let paramEntry = { ...modelCopy.recParams[rowIndex][index] };
                return buildParameter(param, paramEntry);
              }
              return param;
            });

            modelCopy.recParams[rowIndex] = recParams;
          } else {
            let nonRecParams = modelCopy.modelParams.map((param) => {
              if (
                param.name === columnId &&
                param.displaytable === tableIndex
              ) {
                let paramEntry = { ...param };
                return buildParameter(param, paramEntry);
              }
              return param;
            });

            modelCopy.modelParams = nonRecParams;
          }
        } else {
          if (rec) {
            modelCopy.recParams[rowIndex].forEach((param) => {
              if (param.name === columnId) {
                if (
                  Object.prototype.hasOwnProperty.call(param, "customFixed")
                ) {
                  setIsFixed(param.customFixed);
                } else {
                  setIsFixed(param.fixed);
                }
              }
            });
          } else {
            modelCopy.modelParams.forEach((param) => {
              if (
                param.name === columnId &&
                param.displaytable === tableIndex
              ) {
                if (
                  Object.prototype.hasOwnProperty.call(param, "customFixed")
                ) {
                  setIsFixed(param.customFixed);
                } else {
                  setIsFixed(param.fixed);
                }
              }
            });
          }
        }

        if (group !== "") {
          for (let i = 0; i < valGroupsCopy.length; i++) {
            const valGroup = valGroupsCopy[i];

            if (valGroup.groupNumber == group && value.current !== "") {
              if (valGroup.value != parseFloat(value.current)) {
                valGroupsCopy[i] = {
                  ...valGroupsCopy[i],
                  value: parseFloat(value.current),
                };
                groupsNeedsUpdate = true;
              }
              if (
                hardMax.current !== "" &&
                valGroup.hardMax != parseFloat(hardMax.current)
              ) {
                valGroupsCopy[i] = {
                  ...valGroupsCopy[i],
                  hardMax: parseFloat(hardMax.current),
                };
                groupsNeedsUpdate = true;
              }
              if (
                hardMin.current !== "" &&
                valGroup.hardMin != parseFloat(hardMin.current)
              ) {
                valGroupsCopy[i] = {
                  ...valGroupsCopy[i],
                  hardMin: parseFloat(hardMin.current),
                };
                groupsNeedsUpdate = true;
              }
              if (
                isFixed.current != undefined &&
                valGroup.fixed != isFixed.current
              ) {
                valGroupsCopy[i] = {
                  ...valGroupsCopy[i],
                  fixed: isFixed.current,
                };
                groupsNeedsUpdate = true;
              }
              break;
            }
          }
        } else if (removalNeeded.current) {
          if (rec) {
            let recParams = model.recParams[rowIndex];
            recParams = modelCopy.recParams[rowIndex].map((param) => {
              if (param.name === columnId && "group" in param) {
                delete param.group;
              }
              return param;
            });

            modelCopy.recParams[rowIndex] = recParams;
          } else {
            let nonRecRows = modelCopy.modelParams.map((param) => {
              if (param.name == columnId && "group" in param) {
                delete param.group;
              }
              return param;
            });

            modelCopy.modelParams = nonRecRows;
          }
          removalNeeded.current = false;
        }

        if (
          value.current !== "" &&
          value.current !== "-" &&
          value.current !== "+"
        ) {
          const parsedValue = parseFloat(value.current);

          if (rec) {
            let recRows = modelCopy.recTableRows;
            recRows = modelCopy.recTableRows.map((row, index) => {
              if (index === rowIndex) {
                return {
                  ...modelCopy.recTableRows[rowIndex],
                  [columnId]: parsedValue,
                };
              }
              return row;
            });

            modelCopy.recTableRows = recRows;

            let recParamsToUpdate = modelCopy.recParams;
            recParamsToUpdate = recParamsToUpdate.map((paramRow, index) => {
              if (index === rowIndex) {
                return paramRow.map((param) => {
                  if (param.name === columnId) {
                    return { ...param, value: parsedValue };
                  } else {
                    return param;
                  }
                });
              } else {
                return paramRow;
              }
            });

            modelCopy.recParams = recParamsToUpdate;
          } else {
            let nonRecParams = modelCopy.modelParams.map((param) => {
              if (param.name == columnId && param.displaytable === tableIndex) {
                return {
                  ...param,
                  value: parsedValue,
                };
              }
              return param;
            });

            modelCopy.modelParams = nonRecParams;
          }
        }
        if (groupsNeedsUpdate) {
          changeModel(modelCopy, model.FE_ID, valGroupsCopy);
        } else if (!isDeepEqual(model, modelCopy)) {
          changeModel(modelCopy, model.FE_ID);
        }
        if (closeTheWindow.current) {
          closeStickyWindow();
        }
      } else {
        updateFromTable.current = false;
      }
    } catch (error) {
      recordedErrorLog(
        "useEffect for multiple value update handling has failed: ",
        error
      );
    }
  }, [valueState, hardMaxState, hardMinState, isFixedState, group]);

  function buildParameter(param, paramEntry) {
    try {
      delete paramEntry.hardMax;
      delete paramEntry.hardMin;
      delete paramEntry.group;
      paramEntry = {
        ...paramEntry,
        customFixed: false,
      };

      if (!param.fixed) {
        paramEntry = {
          ...paramEntry,
          customFixed: isFixed.current,
        };
      }
      if (hardMax.current !== "") {
        paramEntry = {
          ...paramEntry,
          hardMax: parseFloat(hardMax.current),
        };
      }
      if (hardMin.current !== "") {
        paramEntry = {
          ...paramEntry,
          hardMin: parseFloat(hardMin.current),
        };
      }
      if (group !== "") {
        paramEntry = {
          ...paramEntry,
          group: group,
        };
      }

      return paramEntry;
    } catch (error) {
      recordedErrorLog("Parameter building has failed: ", error);
    }
  }

  const handleValueChange = (event) => {
    try {
      updateFromTable.current = false;
      const newValue = event.target.value;
      if (newValue === "") {
        value.current = "";
        setTempValue("");
        handleApply(newValue, hardMax.current, hardMin.current);
      } else if (newValue == "-" || newValue == "+") {
        value.current = newValue;
        setTempValue(newValue);
        handleApply(newValue, hardMax.current, hardMin.current);
      } else if (
        hardMax.current === "" &&
        hardMin.current === "" &&
        isAllowedNum(newValue)
      ) {
        value.current = newValue;
        setTempValue(newValue);
        handleApply(newValue, hardMax.current, hardMin.current);
      } else if (isAllowedNum(newValue)) {
        const numericHardMax = parseFloat(hardMax.current);
        const numericHardMin = parseFloat(hardMin.current);
        const numericValue = parseFloat(newValue);

        if (numericHardMax !== "" && numericValue > numericHardMax) {
          const model = getModel(modelIndexes);
          limitedToast("Wrong entered value.");
          generateWarningObject(
            `Entered value "${newValue}" is greater than hard maximum "${
              hardMax.current
            }" for model "${model.displayName}" at ${
              rec ? "recuring" : "non-recuring"
            } parameter table; Column ${cell.column.id}; Row ${cell.row.index}`,
            2,
            setWarnings,
            setNewWarningCount
          );
        } else if (numericHardMin !== "" && numericValue < numericHardMin) {
          const model = getModel(modelIndexes);
          limitedToast("Wrong entered value.");
          generateWarningObject(
            `Entered value "${newValue}" is lower than hard minimum "${
              hardMin.current
            }" for model "${model.displayName}" at ${
              rec ? "recuring" : "non-recuring"
            } parameter table; Column ${cell.column.id}; Row ${cell.row.index}`,
            2,
            setWarnings,
            setNewWarningCount
          );
        } else {
          value.current = newValue;
          setTempValue(newValue);
          handleApply(newValue, hardMax.current, hardMin.current);
        }
      }
    } catch (error) {
      recordedErrorLog("Value change handler has failed: ", error);
    }
  };

  useEffect(() => {
    try {
      const model = getModel(modelIndexes);
      if (model != null) {
        value.current = cell.value;
        setTempValue(cell.value);

        //Hard max and min
        const rowIndex = cell.row.index;
        const columnId = cell.column.id;

        let hardMaxLocal = "";
        let hardMinLocal = "";
        let fixed = false;
        let group = "";

        if (rec) {
          [model.recParams[rowIndex]].forEach((paramSet) => {
            if (hardMaxLocal === "" && hardMinLocal === "") {
              for (let i = 0; i < paramSet.length; i++) {
                const param = paramSet[i];
                if (param.name === columnId) {
                  if (param.fixed) {
                    setIsDefaultFixed(true);
                  } else {
                    setIsDefaultFixed(false);
                  }
                  if (hasProperty(param, "customFixed")) {
                    fixed = param.customFixed;
                  } else {
                    fixed = param.fixed;
                  }
                  if (hasProperty(param, "hardMax")) {
                    hardMaxLocal = param.hardMax;
                  }
                  if (hasProperty(param, "hardMin")) {
                    hardMinLocal = param.hardMin;
                  }
                  if (hasProperty(param, "group")) {
                    group = param.group;
                  }
                  break;
                }
              }
            }
          });
        } else {
          model.modelParams.forEach((param) => {
            if (param.name == columnId && param.displaytable === tableIndex) {
              if (param.fixed) {
                setIsDefaultFixed(true);
              } else {
                setIsDefaultFixed(false);
              }
              if (hasProperty(param, "customFixed")) {
                fixed = param.customFixed;
              } else {
                fixed = param.fixed;
              }
              if (hasProperty(param, "hardMax")) {
                hardMaxLocal = param.hardMax;
              }
              if (hasProperty(param, "hardMin")) {
                hardMinLocal = param.hardMin;
              }
              if (hasProperty(param, "group")) {
                group = param.group;
              }
            }
          });
        }

        hardMax.current = hardMaxLocal;
        setHardMaxTemp(hardMaxLocal);
        hardMin.current = hardMinLocal;
        setHardMinTemp(hardMinLocal);
        setIsFixed(fixed);
        setGroup(group);
      }
    } catch (error) {
      recordedErrorLog("useEffect for 'cell' changes has failed: ", error);
    }
  }, [cell]);

  const handleFixChange = () => {
    updateFromTable.current = false;
    if (!isDefaultFixed) {
      setIsFixed(!isFixed.current);
    }
  };

  const handleHmaxChange = (event) => {
    updateFromTable.current = false;
    const newValue = event.target.value;
    if (isAllowedNum(newValue)) {
      setHardMaxTemp(newValue);
    }
  };

  const handleHmaxBlur = () => {
    try {
      const numericHardMaxTemp = parseFloat(hardMaxTempRef.current);
      const numericValue = parseFloat(value.current);
      if (numericHardMaxTemp < numericValue) {
        setIsConfirmOpen(true);
        setIsMin(false);
        setValueForReset(numericValue);
        setValueToSet(numericHardMaxTemp);

        // const model = getModel(modelIndexes);
        // generateWarningObject(
        //   `Entered hard maximum "${hardMaxTemp}" is lower than the current value "${
        //     value.current
        //   }" for model "${model.displayName}" at ${
        //     rec ? "recuring" : "non-recuring"
        //   } parameter table; Column ${cell.column.id}; Row ${cell.row.index}`,
        //   2,
        //   setWarnings,
        //   setNewWarningCount
        // );
        // setHardMaxTemp(hardMax.current);
      } else {
        hardMax.current = hardMaxTempRef.current;
        setHardMax(hardMax.current);
      }
    } catch (error) {
      recordedErrorLog("Hard max blur handler has failed: ", error);
    }
  };

  const handleConfirmAccept = () => {
    try {
      if (isMin) {
        hardMin.current = valueToSet;
        setHardMin(valueToSet);
      } else {
        hardMax.current = valueToSet;
        setHardMax(valueToSet);
      }

      setValue(valueToSet);
      setBaseValue(valueToSet);
      setIsConfirmOpen(false);
      setIsMin(false);
      setValueForReset(null);
      setValueToSet(null);
    } catch (error) {
      recordedErrorLog("Confirm Accept handler has failed: ", error);
    }
  };

  const handleConfirmCancel = () => {
    try {
      if (isMin) {
        setHardMinTemp(valueForReset);
      } else {
        setHardMaxTemp(valueForReset);
      }

      setIsConfirmOpen(false);
      setIsMin(false);
      setValueForReset(null);
      setValueToSet(null);
    } catch (error) {
      recordedErrorLog("Confirm Cancel handler has failed: ", error);
    }
  };

  const handleHminChange = (event) => {
    try {
      updateFromTable.current = false;
      const newValue = event.target.value;
      if (isAllowedNum(newValue)) {
        setHardMinTemp(newValue);
      }
    } catch (error) {
      recordedErrorLog("Hard Min change handler failure: ", error);
    }
  };

  const handleHminBlur = () => {
    try {
      const numericHardMinTemp = parseFloat(hardMinTempRef.current);
      const numericValue = parseFloat(value.current);
      if (numericHardMinTemp > numericValue) {
        // const model = getModel(modelIndexes);
        // generateWarningObject(
        //   `Entered hard minimum "${hardMinTemp}" is greater than the current value "${
        //     value.current
        //   }" for model "${model.displayName}" at ${
        //     rec ? "recuring" : "non-recuring"
        //   } parameter table; Column ${cell.column.id}; Row ${cell.row.index}`,
        //   2,
        //   setWarnings,
        //   setNewWarningCount
        // );
        // setHardMinTemp(hardMin.current);
        setIsConfirmOpen(true);
        setIsMin(true);
        setValueForReset(numericValue);
        setValueToSet(numericHardMinTemp);
      } else {
        hardMin.current = hardMinTempRef.current;
        setHardMin(hardMin.current);
      }
    } catch (error) {
      recordedErrorLog("Hard Min Blur handler failure: ", error);
    }
  };

  const handleGroupClear = () => {
    try {
      updateFromTable.current = false;
      const valGroupsCopy = deepCopy(localGroups.current);

      for (let i = 0; i < valGroupsCopy.length; i++) {
        if (valGroupsCopy[i].groupNumber == group) {
          if (valGroupsCopy[i].memberCount > 1) {
            valGroupsCopy[i] = {
              ...valGroupsCopy[i],
              memberCount: valGroupsCopy[i].memberCount - 1,
            };
          } else {
            valGroupsCopy[i] = {
              ...valGroupsCopy[i],
              value: null,
              hardMax: null,
              hardMin: null,
              memberCount: 0,
            };
          }
          removalNeeded.current = true;
          break;
        }
      }

      setValueGroups(valGroupsCopy);

      setGroup("");
    } catch (error) {
      recordedErrorLog("Group clearing handler has failed: ", error);
    }
  };

  const handleSliderChange = (newSliderValue) => {
    try {
      updateFromTable.current = false;
      isSliderMoving.current = true;
      setSliderValue(newSliderValue);

      const percentage = (newSliderValue - 50) / 50;
      let newValue =
        baseValue !== 0 ? baseValue * (1 + percentage) : 1 * (1 + percentage);
      if (hardMax.current !== "" && newValue > hardMax.current) {
        newValue = hardMax.current;
      }
      if (hardMin.current !== "" && newValue < hardMin.current) {
        newValue = hardMin.current;
      }

      const formattedValue = formatNumber(newValue, 5);

      setTempValue(formattedValue);
      handleApply(formattedValue, hardMax.current, hardMin.current);
    } catch (error) {
      recordedErrorLog("Slider change handler has failed: ", error);
    }
  };

  const handleSliderEnd = () => {
    try {
      setSliderValue(50); // Reset the slider thumb position
      isSliderMoving.current = false;
      value.current = formatNumber(tempValue, 5);
      setBaseValue(value.current);
      handleApply(value.current, hardMax.current, hardMin.current);
    } catch (error) {
      recordedErrorLog("Slider release handler has failed: ", error);
    }
  };

  const handleGroupChange = (event) => {
    try {
      const valGroupsCopy = deepCopy(localGroups.current);
      updateFromTable.current = false;
      const selectedGroup = event.target.value;
      if (group !== selectedGroup) {
        updateFromTable.current = false;

        for (let i = 0; i < valGroupsCopy.length; i++) {
          if (valGroupsCopy[i].groupNumber === group) {
            if (valGroupsCopy[i].memberCount > 1) {
              valGroupsCopy[i] = {
                ...valGroupsCopy[i],
                memberCount: valGroupsCopy[i].memberCount - 1,
              };
            } else {
              valGroupsCopy[i] = {
                ...valGroupsCopy[i],
                value: null,
                hardMax: null,
                hardMin: null,
                memberCount: 0,
              };
            }
          } else if(valGroupsCopy[i].groupNumber === selectedGroup){
            valGroupsCopy[i] = {
              ...valGroupsCopy[i],
              memberCount: valGroupsCopy[i].memberCount + 1,
            };
          }
        }
        localGroups.current = valGroupsCopy;
        setValueGroups(valGroupsCopy);
  
        setGroup(selectedGroup);
      }
    } catch (error) {
      recordedErrorLog("Group change handler failed: ", error);
    }
  };

  function formatNumber(number, significantDigits) {
    try {
      // Convert the number to a string to avoid precision loss in JavaScript
      const numberAsString = number.toString();

      // Create a Decimal instance from the string
      const decimalNumber = new Decimal(numberAsString);

      // Perform operations with arbitrary precision using the Decimal library
      const formattedNumber =
        decimalNumber.toSignificantDigits(significantDigits);

      // Return the final number as a float, cleaned up using decimal.js to avoid artifacts
      return parseFloat(formattedNumber.toFixed());
    } catch (error) {
      recordedErrorLog("Number formatter failure: ", error);
    }
  }

  const marks = Array.from({ length: 11 }, (_, i) => i * 10);

  useEffect(() => {
    function handleMouseDownOutside(event) {
      if (stickyWindowRef.current && !isCellOrStickyWindow(event)) {
        if (
          hardMinTempRef.current !== "" &&
          parseFloat(hardMinTempRef.current) !== parseFloat(hardMin.current)
        ) {
          closeTheWindow.current = true;
          handleHminBlur();
        } else if (
          hardMaxTempRef.current !== "" &&
          parseFloat(hardMaxTempRef.current) !== parseFloat(hardMax.current)
        ) {
          closeTheWindow.current = true;
          handleHmaxBlur();
        } else {
          closeStickyWindow();
        }
      }
    }

    document.addEventListener("mousedown", handleMouseDownOutside);

    return () => {
      document.removeEventListener("mousedown", handleMouseDownOutside);
    };
  }, [stickyWindowRef]);

  return (
    <div
      className={`stickyWindow ${fitOpen ? "fitOpen" : ""}`}
      ref={stickyWindowRef}
      id="stickyWindow"
    >
      <div className="allValueEdits">
        <div className="valueContainer" id="parameter-value-container">
          Value:&nbsp;
          <input
            id="parameter-value-input"
            style={{ width: `${valueInputSize + 20}px` }}
            value={tempValue !== undefined ? tempValue : value.current}
            className="value"
            onChange={handleValueChange}
          />
        </div>
        <div className="hardLimits">
          <div className="hardMaxContainer">
            Hard Max:&nbsp;
            <input
              style={{ width: `${hmaxInputSize + 20}px` }}
              value={hardMaxTemp}
              className="hardMax"
              onChange={handleHmaxChange}
              onBlur={handleHmaxBlur}
            />
          </div>
          <div className="hardMinContainer">
            Hard Min:&nbsp;
            <input
              style={{ width: `${hminInputSize + 20}px` }}
              value={hardMinTemp}
              className="hardMin"
              onChange={handleHminChange}
              onBlur={handleHminBlur}
            />
          </div>
        </div>
        <div className="fixedCheckbox">
          {isFixed.current != undefined ? (
            <>
              <input
                className="checkbox"
                type="checkbox"
                id={"fixCheckbox"}
                name={"fixCheckbox"}
                checked={isFixed.current}
                onChange={() => handleFixChange()}
              />
              <label className="checkboxLabel" htmlFor={"fixCheckbox"}>
                {"Fixed"}
              </label>
            </>
          ) : (
            <></>
          )}
        </div>
        {!isDefaultFixed ? (
          <div className="groupDropDown">
            <div className="dropdown">
              <FormControl fullWidth variant="standard" className="formControl">
                <InputLabel id="group-select-label" className="groupInputLabel">
                  Group
                </InputLabel>
                <Select
                  labelId="group-select-label"
                  id="parameter-group-select"
                  value={group}
                  label="Group"
                  onChange={handleGroupChange}
                  className="select"
                  data-testid="react-select-container"
                  MenuProps={{
                    container: () => document.getElementById("stickyWindow"),
                  }}
                >
                  <MenuItem
                    value={1}
                    className="menuItem"
                    id="group-1-in-select"
                    style={{ backgroundColor: getGroupColor(1) }}
                  >
                    Group 1
                  </MenuItem>
                  <MenuItem
                    value={2}
                    className="menuItem"
                    id="group-2-in-select"
                    style={{ backgroundColor: getGroupColor(2) }}
                  >
                    Group 2
                  </MenuItem>
                  <MenuItem
                    value={3}
                    className="menuItem"
                    style={{ backgroundColor: getGroupColor(3) }}
                  >
                    Group 3
                  </MenuItem>
                  <MenuItem
                    value={4}
                    className="menuItem"
                    style={{ backgroundColor: getGroupColor(4) }}
                  >
                    Group 4
                  </MenuItem>
                  <MenuItem
                    value={5}
                    className="menuItem"
                    style={{ backgroundColor: getGroupColor(5) }}
                  >
                    Group 5
                  </MenuItem>
                  <MenuItem
                    value={6}
                    className="menuItem"
                    style={{ backgroundColor: getGroupColor(6) }}
                  >
                    Group 6
                  </MenuItem>
                  <MenuItem
                    value={7}
                    className="menuItem"
                    style={{ backgroundColor: getGroupColor(7) }}
                  >
                    Group 7
                  </MenuItem>
                  <MenuItem
                    value={8}
                    className="menuItem"
                    style={{ backgroundColor: getGroupColor(8) }}
                  >
                    Group 8
                  </MenuItem>
                  <MenuItem
                    value={9}
                    className="menuItem"
                    style={{ backgroundColor: getGroupColor(9) }}
                  >
                    Group 9
                  </MenuItem>
                  <MenuItem
                    value={10}
                    className="menuItem"
                    style={{ backgroundColor: getGroupColor(10) }}
                  >
                    Group 10
                  </MenuItem>
                </Select>
              </FormControl>
            </div>
            {group !== "" ? (
              <div className="clearGroup" onClick={handleGroupClear}>
                Clear
              </div>
            ) : (
              <></>
            )}
          </div>
        ) : (
          <></>
        )}
      </div>
      <div className="sliderContainer">
        <ReactSlider
          className="slider"
          value={sliderValue}
          onChange={handleSliderChange}
          onAfterChange={handleSliderEnd}
          orientation="vertical"
          invert
          renderThumb={(props) => (
            <div
              className="thumb"
              {...props}
              style={{
                ...props.style,
                height: "20px",
                width: "20px",
                backgroundColor: "#444",
                borderRadius: "50%",
                cursor: "pointer",
                left: "-7px",
              }}
            />
          )}
          renderTrack={(props, state) => (
            <div
              className="track"
              {...props}
              style={{
                ...props.style,
                backgroundColor: state.index === 1 ? "#ddd" : "#444",
                borderRadius: "5px",
                width: "5px",
              }}
            />
          )}
          renderMark={({ mark }) => (
            <div
              className="mark"
              style={{
                position: "absolute",
                backgroundColor: "black",
                height: "2px",
                width: "10px",
                top: `${mark}%`,
                left: "-15px",
              }}
            />
          )}
        />
        <div
          className="marks"
          style={{
            position: "absolute",
            display: "flex",
            flexDirection: "column-reverse",
            justifyContent: "space-between",
            height: "90%",
            right: "5px",
            top: "15px",
          }}
        >
          {marks.map((mark) => {
            const percentage = (mark - 50) * 2;
            return (
              <div
                key={mark}
                className="percentage"
                style={{ fontSize: "10px" }}
              >
                {percentage > 0 ? "+" + percentage : percentage}%
              </div>
            );
          })}
        </div>
      </div>
      {stickyWindowRef.current && (
        <MaxMinConfirmationPopUp
          isOpen={isConfirmOpen}
          onAccept={handleConfirmAccept}
          onCancel={handleConfirmCancel}
          isMin={isMin}
          incomingVal={valueToSet}
          currentVal={valueForReset}
          reference={stickyWindowRef}
        />
      )}
    </div>
  );
}

export default StickyParameterWindow;
